import ApiService from "../apiservice";


class MatriculaService extends ApiService{

    constructor(){
        super("/matricula")
    }


    alterarAulaAtual(dto){
        return this.post("/alterarAulaMat", dto)
    }

    conluirCursoAtual(dto){
        return this.post("/concluirCurso", dto)
    }

    alterarSenha(dto){
        return this.post("/alterarSenha", dto)
    }

    refreshToken(token){
        return this.post("/refreshToken", token)
    }

    setarTutorialEHistoria(){
        return this.post("/tutorialCompleto");
    }

    buscarChatBot(){
        return this.get("/buscarChatBot")
    }

    bloquearLimiteDeAulasSemanal(idcurso){
        return this.get(`/VerificarLimiteSemanal?idcurso=${idcurso}`)
    }

}

export default MatriculaService