import { useEffect } from "react";
import Iframe from 'react-iframe'

const FrameExerciciosDigitais = (props) => {


    useEffect(() => {
        const handler = e => {
           

            if (e.data === 'loja') {
                
                props.history.push('/home');
    
            } else if (e.data >= 0 && e.data <= 100){               
                props.gravarNota(e.data)
            } 
        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // empty array => run only once

    return (
        <Iframe url={props.urlAula}
                    width="100%"
                    height="100%"
                    id="frameDig"
                    name="frameDig"
                    allowFullScreen="true"
                    className="myClassname"
                    display="initial"
                    position="absolute"
                />     
    )
}

export default FrameExerciciosDigitais