import React from 'react'
import TimerMixin from 'react-timer-mixin';
import LocalStorageService from '../app/service/localstorageService';
import AuthService from '../app/service/authService'
import {jwtDecode} from 'jwt-decode';
import * as message from "../components/toastr";
import MatLogadaTempService from '../app/service/matLogadaTempService';




export const AuthContext = React.createContext()
export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = AuthContext.Provider;




class ProvedorAutenticacao extends React.Component {


    state = {
        usuarioAutenticado: null,
        isAutenticado: false,
        isLoading: true,
        tempo: 10,
        nomeAluno: "",
        digitacao: false
    }


    iniciarSessao = (tokenDTO) => {

        const token = tokenDTO.token
        const refresh_token = tokenDTO.refresh_token
        const claims = jwtDecode(token)
        const usuario = {
            matricula: claims.matricula,
            nome: claims.nome,
            senhaPadrao: tokenDTO.senhaPadrao
        }
        this.setState({ nomeAluno: claims.nome })

        AuthService.logar(usuario, token, refresh_token);


        this.setState({ isAutenticado: true, usuarioAutenticado: usuario })

        const decodedToken = jwtDecode(token)
        const expiration = decodedToken.exp
        const hora_dexpiracao = new Date(expiration * 1000);

        TimerMixin.clearInterval(this.intervaloMatLogTemp);
        TimerMixin.clearInterval(this.intervaloToken);

        this.autualizarMatLogadaTemp()

        this.intervaloMatLogTemp = TimerMixin.setInterval(
            () => {                 
                this.autualizarMatLogadaTemp()
             },
             5 * 60 * 1000
          );


        this.intervaloToken = TimerMixin.setInterval(
            () => {               
                if (new Date() >= hora_dexpiracao) {
                    this.atualizarSessao();
                }
             },
            1000
          );


    }

    autualizarMatLogadaTemp = () => {
        try {

            const matTemp = new MatLogadaTempService();
            matTemp.atualizar()
                .then(response => {
                    
                }).catch(erro => {
                    console.error("Erro ao atualizar MatLogadaTemp!")
                })

        } catch (error) {

        }


    }

    atualizarSessao = async () => {  //Verifica se o aluno possui outro horário


        TimerMixin.clearInterval(this.intervaloMatLogTemp);
        TimerMixin.clearInterval(this.intervaloToken);

        const novoToken = await AuthService.refreshToken();
        if (novoToken === null || novoToken === undefined) {
            message.mensagemAlert("Sua aula chegou ao fim!");
            this.encerrarSessao();
        } else {
            this.iniciarSessao(novoToken);
        }

    }


    encerrarSessao = () => {

        TimerMixin.clearInterval(this.intervaloMatLogTemp);
        TimerMixin.clearInterval(this.intervaloToken);

        AuthService.removerUsuarioAutenticado();
        this.setState({ isAutenticado: false, usuarioAutenticado: null, nomeAluno: "" })
    }

    async componentDidMount() {
        const isAutenticado = AuthService.isUsuarioAutenticado()
        if (isAutenticado) {

            const usuario = await AuthService.refreshSession()
            this.setState({
                isAutenticado: true,
                usuarioAutenticado: usuario,
                isLoading: false
            })


            const token = LocalStorageService.obterItem("access_token")

            const decodedToken = jwtDecode(token)
            const expiration = decodedToken.exp
            const hora_dexpiracao = new Date(expiration * 1000);


            this.intervaloMatLogTemp = TimerMixin.setInterval(
                () => {                    
                    this.autualizarMatLogadaTemp()
                 },
                 5 * 60 * 1000
              );  
            
              this.intervaloToken = TimerMixin.setInterval(
                () => {                     
                    if (new Date() >= hora_dexpiracao) {                       
                        this.atualizarSessao();
                    }
                 },
                1000
              );


        } else {
           
            this.setState(previousState => {
                return {
                    ...previousState,
                    isLoading: false
                }
            })

            TimerMixin.clearInterval(this.intervaloMatLogTemp);
            TimerMixin.clearInterval(this.intervaloToken);
        }
    }


    componentWillUnmount(){
        TimerMixin.clearInterval(this.intervaloMatLogTemp);
        TimerMixin.clearInterval(this.intervaloToken);
    }


    render() {

        if (this.state.isLoading) {
            return null;
        }

        const contexto = {
            usuarioAutenticado: this.state.usuarioAutenticado,
            isAutenticado: this.state.isAutenticado,
            iniciarSessao: this.iniciarSessao,
            encerrarSessao: this.encerrarSessao,
            nomeAluno: this.state.nomeAluno,
            digitacao: this.state.digitacao
        }

        return (
            <>
                <AuthProvider value={contexto} >
                    {this.props.children}
                </AuthProvider>
            </>
        )
    }
}

export default ProvedorAutenticacao;