import React, { useState } from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import HomeIcon from "@mui/icons-material/Home";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DuoIcon from "@mui/icons-material/Duo";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import "./CustomSpeedDial.css";
import CloseIcon from "@mui/icons-material/Close";

const CustomSpeedDial = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [posicaoSpeedDial, setPosicaoSpeedDial] = useState("right")

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

 
  const handleOptionClick = (action) => {
    
    switch (action) {
      case "Apostila Digital":
        props.abrirApostila();
        break;
      case "Arquivos":
        props.abrirArquivos();
        break;
      case "Voltar para Home":
        props.voltarHome();
        break;
      case "Dicionário Libras":
        props.abrirDicionarioLibras();
        break;
      case "Alterar posição do ícone":
        if(posicaoSpeedDial === "right"){
            setPosicaoSpeedDial("left")
        }else{
            setPosicaoSpeedDial("right")
        }        
        break;
      default:
        break;
    }

    // Feche o menu após selecionar uma opção (opcional)
    setIsMenuOpen(false);
  };

  return (
    <div className={`custom-speed-dial-${posicaoSpeedDial} ${isMenuOpen ? "open" : ""}`}>
      <button className="floating-button" onClick={toggleMenu}>
        {isMenuOpen ? <CloseIcon /> : <ContactSupportIcon />}
      </button>
      {isMenuOpen && (
        <div className="options">
          <button
            onClick={() => handleOptionClick("Voltar para Home")}           
          >
            <span className={`option-text-${posicaoSpeedDial}`}>Voltar para Home</span>           
              <HomeIcon />           
          </button>
          <button
            onClick={() => handleOptionClick("Apostila Digital")}               
          >
            <span className={`option-text-${posicaoSpeedDial}`}>Apostila Digital</span>            
              <MenuBookIcon />           
          </button>
          <button
            onClick={() => handleOptionClick("Arquivos")}                    
          >
            <span className={`option-text-${posicaoSpeedDial}`}>Arquivos</span>            
              <DriveFileMoveIcon />            
          </button>
          <button
            onClick={() => handleOptionClick("Alterar posição do ícone")}                  
          >
            <span className={`option-text-${posicaoSpeedDial}`}>Alterar posição do ícone</span>           
              <CropRotateIcon />           
          </button>
          {/* Adicione lógica condicional para o botão de Dicionário de Libras */}
          {(props.curso.codigo === 230 ||
            props.curso.codigo === 235 ||
            props.curso.codigo === 236) && (
            <button
              onClick={() => handleOptionClick("Dicionário Libras")}                          
            >
              <span className={`option-text-${posicaoSpeedDial}`}>Dicionário Libras</span>              
                <DuoIcon />            
            </button>
          )}          
        </div>
      )}
    </div>
  );
};

export default CustomSpeedDial;