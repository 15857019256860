import React from "react";
import { Modal } from "react-bootstrap";

function ModalParabens(props) {
    return (
        <Modal show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                🎂
                🥳
                🎉
                🎈
            </Modal.Header>
            <Modal.Body>
                <h2>
                   Parabéns, {props.nomeAluno}                   
                </h2>
                <h5>
                Feliz Aniversário! 🎈🍰🎁 Que seja um dia inesquecível e o início de um ano cheio de felicidade e realizações! 😘❤️
                </h5>
            </Modal.Body>
        </Modal>
    )
}

export default ModalParabens