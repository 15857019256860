import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import App from './main/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/toastr.css'
import { Provider } from 'react-redux';
import store from './redux/store';

// ReactDOM.render(
// <App />,
// document.getElementById("root")

// )


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
);

