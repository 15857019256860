import React from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import LocalStorageService from "../../app/service/localstorageService";
import MatriculaService from "../../app/service/matriculaService";
import * as message from "../../components/toastr";

class ModalAluno extends React.Component {

    constructor(){
        super();
        this.service = new MatriculaService();
    }

    state = {
        novasenha: "",
        repetirsenha: ""

    }

    fechandoModal = () => {
        this.props.onHide()        
        this.setState({ novasenha : "", repetirsenha : ""})
    }


    alterarSenha = (event) => {

        
        if(this.state.novasenha === this.state.repetirsenha && this.state.novasenha !== ""){
            
            const matricula = LocalStorageService.obterItem("_usuario_logado");

            const dto = {
                "matricula" : matricula.matricula,
                "novaSenha" : this.state.novasenha
            }
            
            this.service.alterarSenha(dto)
            .then(response => {                
                message.mensagemSucesso(response.data)
                this.fechandoModal();
            }).catch(erro => {
                console.error(erro.response.data)
                message.mensagemErro(erro.response.data)
            })
            
            
        }else{
           
            message.mensagemAlert("Senha não confere!")
        }
        event.preventDefault();
            
    }


    render() {
        return (
           
                <Modal
                    show={this.props.show}
                    onHide={this.fechandoModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Alterar Senha
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Row>
                        <Form className="mt-3" onSubmit={this.alterarSenha}>
                            <Form.Group className="mb-3" controlId="formSenha">
                                <Form.Label>Nova Senha</Form.Label>
                                <Form.Control type="password" placeholder="Nova Senha" onChange={e => this.setState({ novasenha: e.target.value })} value={this.state.novasenha} autoFocus required/>                                
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formSenhaRepetir">
                                <Form.Label>Repetir Senha</Form.Label>
                                <Form.Control type="password" placeholder="Repetir Senha" onChange={e => this.setState({repetirsenha: e.target.value})} value={this.state.repetirsenha} required/>
                            </Form.Group>   

                            <Button className="mb-2" variant="primary" type="submit">
                                Alterar Senha!
                            </Button>
                        </Form>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }

}

export default ModalAluno