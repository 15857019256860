import React from "react";
import { Modal, Button, Row, Form, Col } from "react-bootstrap";

export default function ModalExerciciosDigitaisObrigatorio(props) {

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Parabéns por concluir a interatividade!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Form className="mt-3" style={{ fontSize: "18px" }}>
                    Agora, o próximo passo é realizar os exercícios digitais.
                    </Form>
                </Row>
                <Row>
                    {/* <Col>
                        <Button className="mb-2 mt-2" style={{width:"100%"}} variant="success" onClick={e => props.irParaInteratividade()}>
                            Sim
                        </Button>
                    </Col>   */}
                    <Col >  
                        <Button className="mb-2 mt-2" style={{width:"100%"}} variant="danger" onClick={e => props.onHide()}>
                            Fechar
                        </Button>                   
                    </Col>                   
                </Row>
            </Modal.Body>
        </Modal>
    )

}