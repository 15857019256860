import React from "react";
import { Modal, Button, Row } from "react-bootstrap";
import LoginService from "../../app/service/loginService";
import * as message from "../../components/toastr"
import { AuthContext } from '../../main/provedorAutenticacao'
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

class ModalMensalidadeAtra extends React.Component {

    constructor() {
        super();
        this.service = new LoginService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ senhainstrutor: event.target.value });
    }

    handleSubmit(event) {

        this.autenticarInstrutor()
        event.preventDefault();
    }

    state = {
        carregando : false,
        senhainstrutor: ""
    }

    autenticarInstrutor = () => {

        this.setState({carregando : true})
        const parametros = { codigo: this.props.codigo, senha: this.props.senha, senhaInstrutor: this.state.senhainstrutor };

        this.service.autenticacaoAutorizada(parametros)
            .then(response => {
                message.mensagemSucesso("Autenticado com sucesso!")
                this.context.iniciarSessao(response.data)
                this.props.irParaHome()
                this.setState({carregando : false}) 
            }).catch(error => {
                console.log(error.response.data)
                if (error.response.data === "Sem horário disponivel!") {
                    message.mensagemAlert("Sem horário disponível!")
                } else {
                    message.mensagemErro(error.response.data)
                }
                this.setState({carregando : false}) 
            })

              
    }



    fechandoModal = () => {
        this.props.onHide()
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.fechandoModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Autorização do Instrutor!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.carregando}
                    >

                        <CircularProgress color="inherit" />

                    </Backdrop>
                    <Row>
                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group className="mb-3" controlId="formBasicSenhaInstrutor2" >
                                <Form.Label>Senha do <span style={{ color: "red" }}>Funcionário </span>do Sistema Escola</Form.Label>
                                <Form.Control type="password" placeholder="Senha" onChange={this.handleChange} value={this.state.senhainstrutor} autoFocus required />
                                <br />
                                <Button className="mb-2" variant="primary" type="submit">
                                    Autorizar!
                                </Button>
                            </Form.Group>
                        </Form>
                    </Row>
                </Modal.Body>
            </Modal >
        )
    }

}

ModalMensalidadeAtra.contextType = AuthContext

export default ModalMensalidadeAtra