import ApiService from "../apiservice";

class LoginService extends ApiService{

    constructor(){
        super('/Autenticacao')
    }

    autenticar(credenciais){

        return this.post('/login', credenciais);
    }

    autenticacaoAutorizada(credenciais){
        return this.post('/login/autorizado', credenciais);
    }

}

export default LoginService;