import React from "react";
import { AuthConsumer } from "../main/provedorAutenticacao";
import { Navbar, Nav, Container, Form, Button } from "react-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalStorageService from "../app/service/localstorageService";
import ModalMeusCursos from "../views/modal/modalMeusCursos";
import ModalExerciciosDigitais from "../views/modal/modalExerciciosDigitais";
import { connect } from "react-redux";

import AnalyticsIcon from "@mui/icons-material/Analytics";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CursoService from "../app/service/cursoService";
import * as message from "../components/toastr";
import HomeService from "../app/service/homeService";
import Dropdown from "react-bootstrap/Dropdown";
import BadgeIcon from "@mui/icons-material/Badge";
import Badge from "@mui/material/Badge";
import ModalAlbumFigurinhas from "../views/modal/modalAlbumFigurinhas";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import FingerprintIcon from "@mui/icons-material/Fingerprint";

class Navbard extends React.Component {
  componentDidMount() {
    const usuarioLogado = LocalStorageService.obterItem("_usuario_logado");

    this.buscarExerciciosDigitais();

    this.setState({ nomeAluno: usuarioLogado.nome });
  }

  state = {
    nomeAluno: "",
    modalMeusCursos: false,
    modalExerciciosDigitais: false,
    cursos: [],
    exerciciosDigitais: [],
    porcentagemMat: 0,
    notificacaoAlert: null,
    curso: { aulaAltual: 0 },
    modalFigurinhas: false,
    linkNFT: false,
    linkSalaVirtual: false,
  };

  buscarMeusCusos = () => {
    const service = new CursoService();

    service
      .meusCursos()
      .then((response) => {
        if (response.data !== "Nenhum curso encontrado!") {
          this.setState({ cursos: response.data });

          const cursos = response.data;

          let totalAulasMat = 0;
          let totalAulasFeitasMat = 0;
          let porcentagemMat = 0;

          cursos.map((row, index) => {
            totalAulasMat = totalAulasMat + row.totalDeAulas;
            totalAulasFeitasMat = totalAulasFeitasMat + row.aulaAtual;

            let cur = row;
            let porcentagem = (row.aulaAtual * 100) / row.totalDeAulas;

            cur.andamento = Math.trunc(porcentagem);

            if (cur.status === "A") {
              cur.status = "Andamento";
            } else if (cur.status === "C") {
              cur.status = "Concluído";
            } else {
              cur.status = "Espera";
            }

            this.setState({ cursos, [index]: cur });
            return null;
          });

          porcentagemMat = (totalAulasFeitasMat * 100) / totalAulasMat;

          this.setState({ porcentagemMat: Math.trunc(porcentagemMat) });
          this.setState({ modalMeusCursos: true });
        } else {
          message.mensagemAlert("Nenhum curso em sua matrícula!");
        }
      })
      .catch((error) => {
        console.error(error.response);
        message.mensagemErro("Não foi possível buscar os cursos!");
      });
  };

  verificarSeOCursoTemAlbumDeFigurinhas = (curso) => {
    //O aluno vai ganhar sua primeira figurinha na aula 2
    if (curso.descricao === "GAMES TEEN") {
      if (curso.aulaAtual === 32 || curso.aulaAtual % 2 !== 0) {
        this.setState({ notificacaoAlert: 1 });
      } else {
        this.setState({ notificacaoAlert: 0 });
      }
    } else {
      this.setState({ notificacaoAlert: null });
    }
  };

  /**
   * Link para cursos do Metaverso
   */
  verificarSeOCursoMetaverso = (curso) => {
    if (curso.descricao === "METAVERSO") {
      if (curso.aulaAtual >= 27) {
        this.setState({ linkNFT: true });
      }
      if (curso.aulaAtual >= 30) {
        this.setState({ linkSalaVirtual: true });
      }
    }
  };

  abrirModalFigurinha = () => {
    if (this.state.curso.aulaAtual <= 2) {
      message.mensagemAlert(
        "Você irá receber sua primeira figurinha na aula 2!"
      );
    }

    this.setState({ modalFigurinhas: true });
  };

  buscarExerciciosDigitais = () => {
    const homeservice = new HomeService();

    homeservice
      .buscarExerciciosDigitais()
      .then((response) => {
        const curso = response.data;

        this.setState({ curso: curso });

        this.verificarSeOCursoTemAlbumDeFigurinhas(curso);
        this.verificarSeOCursoMetaverso(curso);

        let cur = "";
        let mod = "";
        if (curso != null && curso.exerciciosDigitais === "S") {
          if (curso.descricao.includes("PORT")) {
            cur = "port";
          } else if (curso.descricao.includes("MAT")) {
            cur = "mat";
          } else if (curso.descricao.includes("PRÉ-ALFABETIZAÇÃO")){
            cur = "pre_alfab";
          } else {
            cur = "programacao"
          }

          if (curso.descricao.includes("1")) {
            mod = "1";
          } else if (curso.descricao.includes("2")) {
            mod = "2";
          } else if (curso.descricao.includes("3")) {
            mod = "3";
          } else if (curso.descricao.includes("4")) {
            mod = "4";
          } else if (curso.descricao.includes("5")) {
            mod = "5";
          }

          const exercicios = [];

          if (cur === "pre_alfab" || cur === "programacao") {
            for (let index = 1; index <= curso.aulaAtual; index++) {
              exercicios.push({
                aula: index,
                link: `/playerExerciciosDigitais/${cur}/mod/0/aula/${index}`,
                id: curso.id,
              });
              // exercicios.push({ aula: index, link: `http://acessocurso.com.br/ead/cursos/exer_digit/pre_alfab/aula${index}/index.html` })
            }
          } else {
            for (let index = 1; index <= curso.aulaAtual; index++) {
              // exercicios.push({ aula: index, link: `http://acessocurso.com.br/ead/cursos/exer_digit/${cur}${mod}/aula${index}/index.html` })
              exercicios.push({
                aula: index,
                link: `/playerExerciciosDigitais/${cur}/mod/${mod}/aula/${index}`,
                id: curso.id,
              });
            }
          }

          this.setState({ exerciciosDigitais: exercicios });
        }
      })
      .catch((erro) => {
        console.error("Erro ao buscar cursoAtual NavBar");
        console.error(erro.response);
      });
  };

  abrirModalExercicios = () => {
    this.buscarExerciciosDigitais();
    this.setState({ modalExerciciosDigitais: true });
  };

  render() {
    return (
      <>
        <ModalMeusCursos
          show={this.state.modalMeusCursos}
          onHide={() => this.setState({ modalMeusCursos: false })}
          cursos={this.state.cursos}
          porcentagemMat={this.state.porcentagemMat}
        />
        <ModalExerciciosDigitais
          show={this.state.modalExerciciosDigitais}
          onHide={() => this.setState({ modalExerciciosDigitais: false })}
          exercicios={this.state.exerciciosDigitais}
          history={this.props.history}
        />

        <ModalAlbumFigurinhas
          show={this.state.modalFigurinhas}
          onHide={() => this.setState({ modalFigurinhas: false })}
          aulaAtual={this.state.curso.aulaAtual}
        />

        <Navbar
          bg="light"
          expand="lg"
          variant="light"
          style={{ boxShadow: "10px 5px 5px black" }}
        >
          <Container>
            <Navbar.Brand href="#/home">Portal do Aluno</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#/reverAula">Rever Aula</Nav.Link>
                <Nav.Link
                  href="#"
                  onClick={() => {
                    this.buscarMeusCusos();
                  }}
                >
                  Meus Cursos
                  <AnalyticsIcon color="action" />
                </Nav.Link>
                {this.state.exerciciosDigitais.length > 0 ? (
                  <Nav.Link
                    href="#"
                    onClick={() => {
                      this.abrirModalExercicios();
                    }}
                  >
                    Exercícios Digitais
                    <AddTaskIcon color="action" />
                  </Nav.Link>
                ) : (
                  <></>
                )}

                {this.state.notificacaoAlert != null ? (
                  <Nav.Link
                    href="#"
                    onClick={() => {
                      this.abrirModalFigurinha();
                    }}
                  >
                    {this.state.notificacaoAlert === 0 ? (
                      <span style={{ marginRight: "5px", fontWeight: "bold" }}>
                        Álbum de Figurinhas
                      </span>
                    ) : (
                      <Badge badgeContent="!" color="info">
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          Álbum de Figurinhas
                        </span>
                      </Badge>
                    )}
                  </Nav.Link>
                ) : (
                  <></>
                )}

                {this.state.linkNFT ? (
                  <Nav.Link
                    href="https://opensea.io/GOMetaverso"
                    target="_blank"
                  >
                    <span>NFT</span>

                    <FingerprintIcon color="action" />
                  </Nav.Link>
                ) : (
                  <></>
                )}

                {this.state.linkSalaVirtual ? (
                  <Nav.Link
                    href="https://hubs.mozilla.com/link/4GQN5Xq"
                    target="_blank"
                  >
                    <span>Sala Virtual </span>
                    <CastForEducationIcon color="action" />
                  </Nav.Link>
                ) : (
                  <></>
                )}
              </Nav>

              <Form className="d-flex">
                <Navbar.Brand>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <PersonIcon fontSize={"medium"} /> {this.state.nomeAluno}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/perfilALuno">
                        <BadgeIcon /> Meu Perfil
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Navbar.Brand>
              </Form>
              <Button
                onClick={this.props.deslogar}
                href="#/login"
                variant="primary"
                render="{this.props.isUsuarioAutenticado}"
              >
                Sair
                <LogoutIcon />
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state,
});

const nav = (props) => {
  return (
    <AuthConsumer>
      {(context) => (
        <Navbard
          isUsuarioAutenticado={context.isAutenticado}
          deslogar={context.encerrarSessao}
          nomeAluno={context.nomeAluno}
          curso={props.usuario.usuario.curso}
        />
      )}
    </AuthConsumer>
  );
};

export default connect(mapStateToProps)(nav);
