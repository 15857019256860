import React from "react";
import { Modal, Button, Form, Row, Accordion } from "react-bootstrap";
import FuncionarioService from "../../app/service/funcionarioService";
import LocalStorageService from '../../app/service/localstorageService'
import MatriculaService from "../../app/service/matriculaService";
import Divider from '@mui/material/Divider';
import * as message from "../../components/toastr"
import DigitacaoService from "../../app/service/digitacaoService";

class ModalInstrutor extends React.Component {


    constructor() {

        super();
        this.serviceIns = new FuncionarioService();
        this.serviceMat = new MatriculaService();
        this.serviceDig = new DigitacaoService();
    }

    state = {

        aula: "",
        senhainstrutor: "",
        instrutorAutenticado: false,
        aulaDigi: "",
        digitacao: false,
        maxErros: null,
        maxAcertos: null
        
    }

    componentDidMount() {
        this.buscarParametrosDigitacao();
    }

    trocarAulaAtual = (event) => {

        if (this.props.cursoAtual.aula > this.props.cursoAtual.n_aulas || this.props.cursoAtual.aula < 1) {

            message.mensagemAlert("Digite um número correto")

        } else {

            const usuarioLogado = LocalStorageService.obterItem("_usuario_logado");

            const dto = {
                senha: this.state.senhainstrutor,
                idcliente: usuarioLogado.idcliente,
                aula: this.state.aula,
                matricula: usuarioLogado.matricula
            }

            this.serviceMat.alterarAulaAtual(dto)
                .then(response => {

                    message.mensagemSucesso(response.data)
                    this.props.buscarCursoAtual()
                }).catch(error => {
                    console.error(error.response.data)
                    message.mensagemErro(error.response.data)
                })

            this.setState({ aula: "" })
            this.fechandoModal()
        }
        event.preventDefault();
    }


    concluirCursoAtual = () => {

        const usuarioLogado = LocalStorageService.obterItem("_usuario_logado");

        const dto = {
            senha: this.state.senhainstrutor,
            idcliente: usuarioLogado.idcliente,
            matricula: usuarioLogado.matricula
        }

        this.serviceMat.conluirCursoAtual(dto)
            .then(response => {
                message.mensagemSucesso(response.data)
                this.props.buscarCursoAtual()
            }).catch(error => {
                console.error(error.response.data)
                message.mensagemErro(error.response.data)

            })


        this.fechandoModal()

    }

    concluirCursoDig = () => {

        const usuarioLogado = LocalStorageService.obterItem("_usuario_logado");

        const dto = {
            senha: this.state.senhainstrutor,
            idcliente: usuarioLogado.idcliente,
            matricula: usuarioLogado.matricula
        }

        this.serviceDig.conluirCursoDigitacao(dto)
            .then(response => {
                message.mensagemSucesso(response.data)
                this.props.buscarCursoAtual()
            }).catch(error => {
                console.error(error.response.data)
                message.mensagemErro(error.response.data)

            })


        this.fechandoModal()

    }

    trocarAulaAtualDig = (event) => {

        if (this.props.cursoAtual.aulaDigi < 1 || this.props.cursoAtual.aulaDigi > 43) {


            message.mensagemAlert("Digite um número correto")

        } else {


            const dto = {
                senha: this.state.senhainstrutor,
                idcliente: null,
                aula: this.state.aulaDigi,
                matricula: null
            }

            this.serviceDig.alterarAulaDig(dto)
                .then(response => {
                    message.mensagemSucesso(response.data)
                    this.props.buscarCursoAtual()
                }).catch(error => {
                    console.error(error.response.data)
                    message.mensagemErro(error.response.data)
                })

            this.setState({ aulaDigi: "" })
            this.fechandoModal()
        }
        event.preventDefault();
    }


    autenticarInstrutor = (event) => {


        const dto = {
            senha: this.state.senhainstrutor
        }

        this.serviceIns.autenticar(dto)
            .then(response => {
                const curso = LocalStorageService.obterItem("_curso_atual");
                this.setState({ instrutorAutenticado: true, cursoAtual: curso, digitacao: curso.digitacao })
                message.mensagemSucesso(response.data)
            }).catch(error => {
                console.error(error.response.data)
                message.mensagemErro(error.response.data)
            })

        event.preventDefault();
    }

    fechandoModal = () => {
        this.props.onHide()
        this.setState({ senhainstrutor: "", instrutorAutenticado: false })
    }

    buscarParametrosDigitacao = () => {

        this.serviceDig.buscarParametrosDigitacao()
            .then(response => {
                this.setState({ maxAcertos: response.data.maximodigitacao })
                this.setState({ maxErros: response.data.maximoerro })
            }).catch(erro => {
                console.error("Erro ao buscar Parametros da Digitação Erro: ",erro);
                //message.mensagemErro("Erro ao buscar Parametros da Digitação")
            })
    }

    alterarParametrosDigitacao = (event) => {
        const dto = { maximodigitacao: this.state.maxAcertos, maximoerro: this.state.maxErros }

        this.serviceDig.setarParametrosDigitacao(dto)
            .then(response => {
                message.mensagemSucesso("Parametro alterado com Sucesso!");
                this.buscarParametrosDigitacao();
            }).catch(erro => {
                console.error(erro.response)
            })
        event.preventDefault();
    }

    render() {


        return (
            <Modal
                show={this.props.show}
                onHide={this.fechandoModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Área do Instrutor
                    </Modal.Title>
                </Modal.Header>
                {this.state.instrutorAutenticado ?
                    <Modal.Body>

                        <Accordion>
                            {this.props.cursoAtual.descricao !== "DIGITACAO 2.0"
                                ?
                                <>

                                    <Accordion.Item eventKey={1}>
                                        <Accordion.Header>Curso Interativo</Accordion.Header>
                                        <Accordion.Body>
                                            <h4>CURSO {this.props.cursoAtual.descricao} AULA {this.props.cursoAtual.aulaAtual}</h4>
                                            <Row>
                                                <Form className="mt-3" onSubmit={this.trocarAulaAtual}>
                                                    <Form.Group className="mb-3" controlId="formBasicCodigo">
                                                        <Form.Label>Alterar Aula</Form.Label>
                                                        <Form.Control type="number" placeholder="Número da aula" onChange={e => this.setState({ aula: e.target.value })} value={this.state.aula} min="1" max={this.props.cursoAtual.n_aulas} required />
                                                    </Form.Group>

                                                    <Button className="mb-2" variant="primary" type="submit">
                                                        Alterar aula atual
                                                    </Button>
                                                    <span> </span>
                                                    <Button className="mb-2" variant="secondary" onClick={this.concluirCursoAtual}>
                                                        Concluir Curso!
                                                    </Button>
                                                </Form>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item >
                                </>
                                :
                                <></>


                            }
                            {/* ############################Digitação##############################################*/}
                            <Divider />
                            {this.state.digitacao === true ?
                                <>

                                    <Accordion.Item eventKey={2}>
                                        <Accordion.Header>Digitação</Accordion.Header>
                                        <Accordion.Body>

                                            <Row>

                                                <Form className="mt-3" onSubmit={this.trocarAulaAtualDig}>
                                                    <Form.Group className="mb-3" controlId="formBasicCodigo">
                                                        <Form.Label>Alterar Aula</Form.Label>
                                                        <Form.Control type="number" placeholder="Número da aula" onChange={e => this.setState({ aulaDigi: e.target.value })} value={this.state.aulaDigi} min="1" max="43" required />
                                                    </Form.Group>
                                                    <Button className="mb-2" variant="primary" type="submit">
                                                        Alterar aula atual
                                                    </Button>
                                                    <span> </span>
                                                    <Button className="mb-2" variant="secondary" onClick={this.concluirCursoDig}>
                                                        Concluir Curso!
                                                    </Button>
                                                </Form>

                                                <Accordion id="parametro">
                                                    <Accordion.Item eventKey={1}>
                                                        <Accordion.Header>Parametros Digitação</Accordion.Header>
                                                        <Accordion.Body>
                                                            {this.state.maxAcertos === null || this.state.maxErros == null
                                                                ?
                                                                <>Carregando...</>
                                                                :
                                                                <Row>
                                                                    <Form className="mt-3" onSubmit={this.alterarParametrosDigitacao}>
                                                                        <Form.Group className="mb-3" controlId="formBasicParaAcertos">
                                                                            <Form.Label>Quantidade de Acertos</Form.Label>
                                                                            <Form.Control type="number" placeholder="Quantidade" onChange={e => this.setState({ maxAcertos: e.target.value })} value={this.state.maxAcertos} min="1" required />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="formBasicParaErros">
                                                                            <Form.Label>Quantidade de Erros</Form.Label>
                                                                            <Form.Control type="number" placeholder="Quantidade" onChange={e => this.setState({ maxErros: e.target.value })} value={this.state.maxErros} min="1" required />
                                                                        </Form.Group>
                                                                        <Button className="mb-2" variant="primary" type="submit">
                                                                            Alterar
                                                                        </Button>
                                                                    </Form>
                                                                </Row>
                                                            }

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>

                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>


                                </>
                                :
                                <>

                                </>
                            }

                        </Accordion>

                    </Modal.Body>
                    :
                    <Modal.Body>
                        <Row>
                            <Form className="mt-3" onSubmit={this.autenticarInstrutor}>
                                <Form.Group className="mb-3" controlId="formBasicCodigo">
                                    <Form.Label>Senha do <span style={{ color: "red" }}>Funcionário</span> do Sistema Escola</Form.Label>
                                    <Form.Control type="password" placeholder="Senha" onChange={e => this.setState({ senhainstrutor: e.target.value })} value={this.state.senhainstrutor} autoFocus required />
                                </Form.Group>

                                <Button className="mb-2" variant="primary" type="submit">
                                    Autorizar!
                                </Button>
                            </Form>
                        </Row>
                    </Modal.Body>
                }



            </Modal>
        )
    }

}

export default ModalInstrutor