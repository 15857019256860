import ApiService from "../apiservice";


class NpsService extends ApiService{

    constructor(){
        super("/NPS")
    }


    reponderNPS(idcurso){
        return this.get(`/ResponderNPS?idcurso=${idcurso}`)
    }

    gravarNPS(npsDTO, idcurso){
        return this.post(`/GravarNPS?idcurso=${idcurso}`,npsDTO)
    }
}

export default NpsService