import ApiService from "../apiservice";


class NotificacaoService extends ApiService{

    constructor(){
        super("/Notificacao")
    }


    buscar(){
        return this.get("")
    }

    
}

export default NotificacaoService