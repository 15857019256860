import React, { useState } from "react"
import Grid from '@mui/material/Grid';
import { Card, Typography, CardContent, CardMedia, CardActionArea } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as message from "./toastr";
import '../views/css/card.css'
import LocalStorageService from "../app/service/localstorageService";
import { Modal, Button, Form, Row } from "react-bootstrap";
import FuncionarioService from "../app/service/funcionarioService";
import NotasService from "../app/service/notas";
import ModalExerciciosDigitaisObrigatorio from "../views/modal/modalExerciciosDigitaisObrigatorio";




export function Layout({ ...props }) {


    const [modalInstrutor, setModalInstrutor] = useState(false);
    const [modalExcerDig, setModalExcerDig] = useState(false);



    const buscarAulaAtualDigitacao = () => {

        const cursoAtual = LocalStorageService.obterItem("_curso_atual");
        return cursoAtual.aulaAtualDigitacao;
    }

    const redirectDigitacao = () => {
        props.history.push('/digitacao');

    }


    const redirectPlayer = () => {
        const cursoAtual = LocalStorageService.obterItem("_curso_atual")
      
        if (cursoAtual.exerciciosDigitais === "S") {
            const serviceNota = new NotasService()
            serviceNota.verificaSePossuiNota(props.curso.id, props.curso.aulaAtual)
                .then(response => {
                  
                    if (response.data === false) {
                        if (props.curso.bloqueado === true) {
                            message.mensagemAlert("Autorização Necessária")
                            setModalInstrutor(true)
                        } else if (props.curso.id !== "") {

                            props.history.push('/player');

                        } else {
                            message.mensagemAlert("Não possui curso ativo!");
                        }
                    } else {
                        setModalExcerDig(true)
                    }
                }).catch(erro => {
                    message.mensagemErro("Erro ao verificar se o aluno já realizou a interatividade")
                    console.error(erro.data)
                    props.history.push('/player');
                })
        } else {

            if (props.curso.bloqueado === true) {
                message.mensagemAlert("Autorização Necessária")
                setModalInstrutor(true)
            } else if (props.curso.id !== "") {

                props.history.push('/player');

            } else {
                message.mensagemAlert("Não possui curso ativo!");
            }
        }
    }

    const aulaAtualDig = () => {
        const aula = buscarAulaAtualDigitacao();
        return aula === 0 ? 1 : aula;
    }

    const liberarAcesso = (event, senha) => {


        let funcionario = { senha: senha }

        const service = new FuncionarioService();

        service.autenticar(funcionario)
            .then(response => {
                props.history.push('/player');
            }).catch(erro => {
                console.error("Não autorizado!")
                message.mensagemErro("Não autorizado!")
            })

        event.preventDefault();
    }



    const ModalIns = (props) => {

        const [senhaInstrutorBloq, setSenhaInstrutorBloq] = useState("");


        return (
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Área do Instrutor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form className="mt-3" onSubmit={e => liberarAcesso(e, senhaInstrutorBloq)}>
                            <Form.Group className="mb-3" controlId="formSenhaInstrutorCursoBloqueado">
                                <Form.Label>Senha do Instrutor</Form.Label>
                                <Form.Control type="password" placeholder="Senha" onChange={e => setSenhaInstrutorBloq(e.target.value)} value={senhaInstrutorBloq} maxLength="30" autoFocus />
                            </Form.Group>

                            <span> </span>
                            <Button className="mb-2" variant="primary" onClick={e => liberarAcesso(e, senhaInstrutorBloq)}>
                                Liberar!
                            </Button>
                        </Form>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }


    if (props.layout === 1) {    // matricula com curso interativo e digitação
        return (
            <>
                <Grid item xs={12} md={3.33} sm={12}>
                    <CardActionArea onClick={redirectPlayer} style={{ boxShadow: '6px 6px 6px  black' }}>
                        <Card sx={{ height: 300 }}>
                            <div className="overflow">
                                <CardMedia className="img-card"
                                    component="img"
                                    alt="interatividade"
                                    height="200"
                                    image="interatividade.jpg"
                                >
                                </CardMedia>
                            </div>
                            <CardContent>
                                {props.carregando === true ?
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <>
                                        <Typography gutterBottom style={{ fontSize: '1.1rem', fontFamily: "Rubik" }} component="div">
                                            INTERATIVIDADE {props.curso.descricao}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Aula {props.curso.aulaAtual}
                                        </Typography>
                                    </>
                                }

                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>

                <Grid item xs={12} md={3.33} sm={12}>
                    <CardActionArea onClick={redirectDigitacao} style={{ boxShadow: '6px 6px 6px  black' }}>
                        <Card sx={{ height: 300 }} >
                            <div className="overflow">
                                <CardMedia className="img-card"
                                    component="img"
                                    alt="interatividade"
                                    height="200"
                                    image="digitacao.jpg"
                                >
                                </CardMedia>
                            </div>
                            <CardContent>
                                {props.carregando === true ?
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <>
                                        <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 25, fontFamily: "Rubik" }}>
                                            DIGITAÇÃO
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Aula {aulaAtualDig()}
                                        </Typography>
                                    </>
                                }

                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>


                <Grid item xs={12} md={3.33} sm={12}>
                    <CardActionArea onClick={props.abrirModalNota} style={{ boxShadow: '6px 6px 6px  black' }}>
                        <Card sx={{ height: 300 }} >
                            <div className="overflow">
                                <CardMedia className="img-card"
                                    component="img"
                                    alt="nota"
                                    height="200"
                                    image="notas.jpg"
                                >
                                </CardMedia>
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 25, fontFamily: "Rubik" }}>
                                    NOTAS
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
                <ModalIns
                    show={modalInstrutor}
                    onHide={e => setModalInstrutor(false)} />

                <ModalExerciciosDigitaisObrigatorio
                    show={modalExcerDig}
                    onHide={e => setModalExcerDig(false)}
                    irParaInteratividade={e => { props.history.push('/player') }}
                />
            </>
        )
    } else if (props.layout === 2) {  // matricula só com digitação
        return (
            <>
                <Grid item xs={12} md={5} sm={12}>
                    <CardActionArea onClick={redirectDigitacao} style={{ boxShadow: '6px 6px 6px  black' }}>
                        <Card sx={{ height: 300 }}>
                            <div className="overflow">
                                <CardMedia className="img-card"
                                    component="img"
                                    alt="interatividade"
                                    height="200"
                                    image="digitacao.jpg"
                                >
                                </CardMedia>
                            </div>
                            <CardContent>
                                {props.carregando === true ?
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <>
                                        <Typography gutterBottom style={{ fontSize: 25, fontFamily: "Rubik" }} component="div">
                                            DIGITAÇÃO
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Aula {aulaAtualDig()}
                                        </Typography>
                                    </>
                                }

                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>

                <Grid item xs={12} md={5} sm={12}>
                    <CardActionArea onClick={props.abrirModalNota} style={{ boxShadow: '6px 6px 6px  black' }}>
                        <Card sx={{ height: 300 }} className="card">
                            <div className="overflow">
                                <CardMedia className="img-card"
                                    component="img"
                                    alt="nota"
                                    height="200"
                                    image="notas.jpg"
                                >
                                </CardMedia>
                            </div>
                            <CardContent>
                                <Typography gutterBottom style={{ fontSize: 25, fontFamily: "Rubik" }} component="div">
                                    NOTAS
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
                <ModalIns
                    show={modalInstrutor}
                    onHide={e => setModalInstrutor(false)} />

                <ModalExerciciosDigitaisObrigatorio
                    show={modalExcerDig}
                    onHide={e => setModalExcerDig(false)}
                    irParaInteratividade={e => { props.history.push('/player') }}
                />
            </>
        )
    } else if (props.layout === 3) {  //matricula só com curso interativo
        return (
            <>
                <Grid item xs={12} md={5} sm={12}>
                    <CardActionArea onClick={redirectPlayer} style={{ boxShadow: '6px 6px 6px  black' }}>
                        <Card sx={{ height: 300 }}>
                            <div className="overflow">
                                <CardMedia className="img-card"
                                    component="img"
                                    alt="interatividade"
                                    height="200"
                                    image="interatividade.jpg"
                                >
                                </CardMedia>
                            </div>
                            <CardContent>
                                {props.carregando === true ?
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <>
                                        <Typography gutterBottom style={{ fontSize: '1.1rem', fontFamily: "Rubik" }} component="div">
                                            INTERATIVIDADE {props.curso.descricao}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Aula {props.curso.aulaAtual}
                                        </Typography>
                                    </>
                                }

                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>

                <Grid item xs={12} md={5} sm={12}>
                    <CardActionArea onClick={props.abrirModalNota} style={{ boxShadow: '6px 6px 6px  black' }}>
                        <Card sx={{ height: 300 }}>
                            <div className="overflow">
                                <CardMedia className="img-card"
                                    component="img"
                                    alt="nota"
                                    height="200"
                                    image="notas.jpg"
                                >
                                </CardMedia>
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 25, fontFamily: "Rubik" }}>
                                    NOTAS
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
                <ModalIns
                    show={modalInstrutor}
                    onHide={e => setModalInstrutor(false)} />

                <ModalExerciciosDigitaisObrigatorio
                    show={modalExcerDig}
                    onHide={e => setModalExcerDig(false)}
                    irParaInteratividade={e => { props.history.push('/player') }}
                />
            </>
        )
    }
}