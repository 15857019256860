import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useEffect, useState } from "react";
import NotificacaoService from "../app/service/notificacaoService";

export default function Notificacao() {
  const [avisoAtualizacao, setAvisoAtualizacao] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");

  useEffect(() => {
    buscarRecadoServidor();
  }, []);

  const buscarRecadoServidor = () => {
    
    const service = new NotificacaoService();
    service.buscar().then(response => {
            if(response.data !== ""){
                setTitulo(response.data.titulo)
                setDescricao(response.data.descricao)
                setAvisoAtualizacao(true)
            }
    }).catch(erro => {
        console.error("Erro notificaco: ",erro)
    })


  };

  return (
    <Snackbar
      open={avisoAtualizacao}
      autoHideDuration={10000}
      onClose={() => {
        setAvisoAtualizacao(false);
      }}
    >
      <Alert severity="info">
        <AlertTitle>{titulo}</AlertTitle>
        {descricao}
      </Alert>
    </Snackbar>
  );
}
