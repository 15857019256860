import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Iframe from "react-iframe";

const PlayerApostila = () => {
  const [linkApostila, setLinkApostila] = useState("");

  const getCookie = (nomeCookie) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(nomeCookie + "=")) {
        return cookie.substring(nomeCookie.length + 1);
      }
    }
    return null;
  };

  useEffect(() => {
    document.title = "Portal do Aluno - Apostila";
    const link = getCookie("bGlua0Fwb3N0aWxh");
    setLinkApostila(atob(decodeURIComponent(link)));
  }, []);

  return (
    <Iframe
      url={linkApostila}
      width="100%"
      height="100%"
      id="frameDig"
      name="frameDig"
      allowFullScreen="true"
      className="myClassname"
      display="initial"
      position="absolute"
    />
  );
};

export default withRouter(PlayerApostila);
