import ApiService from "../apiservice";

class MatLogadaTempService extends ApiService{

    constructor(){
        super('/atualizarStatusOnline')
    }

    atualizar(){
        return this.post('/statusOnline');
    }
}

export default MatLogadaTempService;