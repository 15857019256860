import LocalStorageService from './localstorageService'
import {jwtDecode} from 'jwt-decode';
import ApiService from '../apiservice';
import axios from 'axios';


export const USUARIO_LOGADO = '_usuario_logado'
export const TOKEN = 'access_token'
export const CURSO = '_curso_atual'
export const REFRESH_TOKEN = 'refresh_token'

const baseURL = process.env.REACT_APP_API_URL


export default class AuthService {

    static isUsuarioAutenticado() {
        const token = LocalStorageService.obterItem(TOKEN)
        if (!token) {
            return false;
        }
        const decodedToken = jwtDecode(token)        
        const expiration = decodedToken.exp
        this.tempo_expiracao = expiration
        const isTokenInvalido = Date.now() >= (expiration * 1000)

        return !isTokenInvalido;
    }

    static removerUsuarioAutenticado() {
        LocalStorageService.removerItem(USUARIO_LOGADO)
        LocalStorageService.removerItem(TOKEN);
        LocalStorageService.removerItem(CURSO);
        LocalStorageService.removerItem(REFRESH_TOKEN);
    }

    static logar(usuario, token, refresh_token) {
        LocalStorageService.adicionarItem(USUARIO_LOGADO, usuario)
        LocalStorageService.adicionarItem(TOKEN, token);
        LocalStorageService.adicionarItem(REFRESH_TOKEN, refresh_token);
        ApiService.registrarToken(token)
    }



    static obterUsuarioAutenticado() {
        return LocalStorageService.obterItem(USUARIO_LOGADO);
    }

    static refreshSession() {
        const token = LocalStorageService.obterItem(TOKEN)
        const refreshToken = LocalStorageService.obterItem(REFRESH_TOKEN)
        const usuario = AuthService.obterUsuarioAutenticado()
        AuthService.logar(usuario, token, refreshToken)
        return usuario;
    }

    static refreshToken() {
        let refresToken2 = { refresh_token: LocalStorageService.obterItem(REFRESH_TOKEN) }

        if(refresToken2.refresh_token === "" || refresToken2.refresh_token === null){

            console.error("Não foi possivel encontrar um refreshToken em sua sessão")
            return null;
        }

        let refresh = axios.post(`${baseURL}matricula/refreshToken`, refresToken2).then(response => {
            
            LocalStorageService.adicionarItem(TOKEN, response.data.token);
            LocalStorageService.adicionarItem(REFRESH_TOKEN, response.data.refresh_token);  
            return response.data;
        }).catch(erro => {
            console.error("ERRO refreshToken")
            return null
        })

        return refresh;
    }

}

