import ApiService from "../apiservice";


class CursoService extends ApiService{

    constructor(){
        super("/curso")
    }

    buscarRefazerAulas(){
        return this.get("/refazer-aula")
    }

    buscarCursoPadrao(codigoCurso){
        return this.get(`/buscarCursoRefazer?codigo=${codigoCurso}`)
    }

    meusCursos(){
        return this.get("/meusCursos")
    }

    bloqueioDeApostila(idcurso){
        return this.get(`/BloqueioDeApostila?idcurso=${idcurso}`)
    } 

    aulaJaRevisada(idCurso, aula){
        return this.get(`/historicoAcessoRevisao?idcurso=${idCurso}&aula=${aula}`)
    }

    gravarAcessoRevisao(idCurso, aula){
        return this.post(`/historicoAcessoRevisao?idcurso=${idCurso}&aula=${aula}`)
    }

}


export default CursoService