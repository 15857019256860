import React from 'react';
import Rotas from './rotas';
import ProvedorAutenticacao from './provedorAutenticacao'


class App extends React.Component{

  render(){
    return(
      <ProvedorAutenticacao>      
      <Rotas />      
      </ProvedorAutenticacao>
    )
  }
}

export default App;
