import ApiService from "../apiservice";


class DigitacaoService extends ApiService{

    constructor(){
        super("/Digitacao")
    }

    buscarAulaAtual(){
        return this.get("/curso-atual-digitacao")
    }

    alterarAulaDig(dto){
        return this.post("/alterarAulaMatDig", dto)
    }

    
    conluirCursoDigitacao(dto){
        return this.post("/concluirCursoDig", dto)
    }

    
    buscarParametrosDigitacao(){
        return this.get("/buscarParametrosDigitacao")
    }

    setarParametrosDigitacao(dto){
        return this.post("/setarParametrosDigitacao", dto)
    }

    gravarTutorial(tutorial){

        return this.post(`/gravarTutorialDigitacao?tutorial=${tutorial}`)

    }
    
    avancarAula(){

        return this.post("/avancarDigitacao")
    }

}


export default DigitacaoService