import React from "react";
import { Container } from "react-bootstrap";
import HomeService from "../app/service/homeService";
import { Card, Typography, CardContent, CardMedia, CardActionArea } from '@mui/material';
import LocalStorageService from "../app/service/localstorageService";
import ModalNota from "./modal/modalNota";
import ModalHorarios from "./modal/modalHorarios";
import ModalInstrutor from "./modal/modalInstrutor";
import ModalAluno from "./modal/modalAluno";
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { AuthContext } from '../main/provedorAutenticacao'
import * as message from "../components/toastr";
import { Layout } from '../components/layoutMenuHome'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import NotasService from "../app/service/notas";
import ModalPremio from "./modal/modalPremio";
import './css/card.css'
import AlunoService from "../app/service/alunoService";
import ModalParabens from "./modal/modalParabens";
import ModalCursoGratis from "./modal/modalCursoGratis";
import { connect } from "react-redux";
// import { adicionarUsuario } from "../redux/usuarioSlice";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import MatriculaService from "../app/service/matriculaService";
import NpsPesquisa from "../components/npsPesquisa";
import AvaliacaoCurso from "../components/avaliacaoCurso";



class Home extends React.Component {


    state = {
        curso: {
            id: "",
            codigo: "",
            n_aulas: "",
            caminho: "",
            descricao: "",
            aulaAtual: "",
            mensagemPremio: ""
        },
        digitacao: false,
        modalShow: false,
        modalHorarioShow: false,
        modalInstrutorShow: false,
        modalAlunoShow: false,
        cursoNotas: [],
        carregando: true,
        modalPremio: false,
        modalParabens: false,
        modalCursoGratis: false,
        modalCursoGratisExibido: null,
        scriptChatBotHelmet: ""
    }

    constructor() {
        super();
        this.service = new HomeService();
        this.serviceNotas = new NotasService();
        this.serviceAluno = new AlunoService();

        this.serviceMatricula = new MatriculaService();

    }


    buscarCursoAtual = () => {

        this.service.buscarCursoAtual()
            .then(response => {

                this.setState({ carregando: false });

                if (response.data.perfil_aluno) {
                    this.props.history.push('/perfilAluno');
                }

                if (response.data.digitacao === true && response.data.codigo !== 220) {
                    this.setState({ layout: 1 }) // matricula com curso interativo e digitação
                } else if (response.data.digitacao === true && response.data.codigo === 220) {
                    this.setState({ layout: 2 }) // matricula só com digitação
                } else {
                    this.setState({ layout: 3 }) //matricula só com curso interativo
                }

                if (response.data.mensagem_premio !== "" && response.data.mensagem_premio !== undefined) {
                    console.debug("msg premio")
                    this.setState({ modalPremio: true })
                }

                if (response.data.msg_curso_gratis !== "" && response.data.msg_curso_gratis !== undefined) {
                    console.debug("msg curso gratis")
                    this.setState({ modalCursoGratis: true })
                }

                this.setState({
                    curso: {
                        id: response.data.id,
                        codigo: response.data.codigo,
                        n_aulas: response.data.n_aulas,
                        caminho: response.data.caminho,
                        descricao: response.data.descricao,
                        aulaAtual: response.data.aulaAtual,
                        liberarSHorario: response.data.liberarSHorario,
                        bloqueado: response.data.bloqueado,
                        mensagemPremio: response.data.mensagem_premio
                    }, msg_curso_gratis: response.data.msg_curso_gratis,
                }, () => {
                    this.verificarLimiteDeAulaSemanal();
                    // const teste = {nome:"teste", curso: this.state.curso}  
                    // console.log(this.props.usuario)
                    //   const { dispatch } = this.props;
                    //  dispatch(adicionarUsuario({...teste}))
                })

                LocalStorageService.adicionarItem('_curso_atual', response.data)

            }).catch(erro => {
                if (erro.response.data === "Nenhum Curso Interativo Encontrado.") {
                    message.mensagemAlert("Nenhum Curso Interativo Encontrado.")
                    LocalStorageService.removerItem("_curso_atual");
                    this.setState({
                        curso: {
                            id: "",
                            codigo: "",
                            n_aulas: "",
                            caminho: "",
                            descricao: "",
                            aulaAtual: ""
                        },
                        layout: 0
                    })
                }
                this.setState({ carregando: false });
            })
    }

    verificarLimiteDeAulaSemanal = () => {

        this.serviceMatricula.bloquearLimiteDeAulasSemanal(this.state.curso.id)
        .then(response => {
            if(response.data === true){
                message.mensagemAlert("Você já realizou o limite de aulas semanal!")
                this.context.encerrarSessao()
            }
        }).catch(erro => {
            console.error("Erro ao buscar limite de aula semanal!")
        })
    }
    
    async componentDidMount() {

        await this.buscarCursoAtual();
        this.verificaSenhaPadrao();
        this.verificarParabens();
        //this.verificarLimiteDeAulaSemanal();
        // this.buscarScriptChatBot()
    }
   

   

    verificarParabens = () => {
        this.serviceAluno.verificarAniversario()
            .then(response => {
                if (response.data === "Parabéns") {
                    let usuarioLogado = LocalStorageService.obterItem("_usuario_logado");
                    if (usuarioLogado.telaParabens === undefined && usuarioLogado.telaParabens !== false) {
                        this.setState({ modalParabens: true })
                        this.setState({ nomeAluno: usuarioLogado.nome })
                        usuarioLogado = { ...usuarioLogado, telaParabens: true }
                        LocalStorageService.adicionarItem("_usuario_logado", usuarioLogado)
                    }
                }
            }).catch(erro => {
                console.error("Erro ao verifica Parabéns!", erro)
            })
    }



    verificaSenhaPadrao = () => {
        const usuarioLogado = LocalStorageService.obterItem("_usuario_logado")
        if (usuarioLogado.senhaPadrao === "S") {
            usuarioLogado.senhaPadrao = "N";
            this.setState({ modalAlunoShow: true });
            LocalStorageService.adicionarItem("_usuario_logado", usuarioLogado)
        }
    }



    redirectFrequencia = () => {
        if (this.state.curso.liberarSHorario === "S") {

            message.mensagemAlert("Matrícula não requer horário agendado!");

        } else {

            this.props.history.push('/frequencia');

        }
    }

    abrirModalInstrutor = () => {
        if (this.state.curso.id !== "") {
            this.setState({ modalInstrutorShow: true })
        } else {
            message.mensagemAlert("Não possui curso ativo!");
        }
    }


    buscarNotas = () => {
        this.serviceNotas.buscarNotas()
            .then(response => {
                this.setState({ cursoNotas: response.data })
            }).catch(erro => {
                console.error(erro.response.data);
            })
    }

    abrirModalNotas = () => {

        this.buscarNotas();
        this.setState({ modalShow: true })

    }


    render() {


        return (
            <>
                
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.carregando}
                >

                    <CircularProgress color="inherit" />

                </Backdrop>

                <Container className="mt-5">


                    <Grid container spacing={2} justifyContent="center"
                        alignItems="center">

                        <Layout layout={this.state.layout} curso={this.state.curso} carregando={this.state.carregando} modalNota={this.state.modalShow} abrirModalNota={() => { this.abrirModalNotas() }} history={this.props.history} />

                        <Grid item xs={12} md={5} sm={12}>
                            <CardActionArea onClick={() => this.setState({ modalHorarioShow: true })} style={{ boxShadow: '6px 6px 6px  black' }}>
                                <Card sx={{ height: 300 }}>
                                    <div className="overflow">
                                        <CardMedia className="img-card"
                                            component="img"
                                            alt="horario"
                                            height="200"
                                            image="horario.jpg"
                                        >
                                        </CardMedia>
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 25, fontFamily: "Rubik" }}>
                                            HORÁRIOS
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                        <Grid item xs={12} md={5} sm={12}>
                            <CardActionArea onClick={this.redirectFrequencia} style={{ boxShadow: '6px 6px 6px  black' }}>
                                <Card sx={{ height: 300 }}>
                                    <div className="overflow">
                                        <CardMedia className="img-card"
                                            component="img"
                                            alt="frequencia"
                                            height="200"
                                            image="frequencia.jpg"
                                        >
                                        </CardMedia>
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 25, fontFamily: "Rubik" }}>
                                            FREQUÊNCIA
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                        <Grid item xs={12} md={5} sm={12}>
                            <CardActionArea onClick={() => this.setState({ modalAlunoShow: true })} style={{ boxShadow: '6px 6px 6px  black' }}>
                                <Card sx={{ height: 300 }}>
                                    <div className="overflow">
                                        <CardMedia className="img-card"
                                            component="img"
                                            alt="instrutor"
                                            height="200"
                                            image="instrutor.jpg"
                                        >
                                        </CardMedia>
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 25, fontFamily: "Rubik" }}>
                                            ALTERAR SENHA
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>

                        <Grid item xs={12} md={5} sm={12}>
                            <CardActionArea onClick={this.abrirModalInstrutor} style={{ boxShadow: '6px 6px 6px  black' }}>
                                <Card sx={{ height: 300 }} >
                                    <div className="overflow">
                                        <CardMedia className="img-card"
                                            component="img"
                                            alt="instrutor"
                                            height="200"
                                            image="area_do_aluno.jpg"
                                        >
                                        </CardMedia>
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom component="div" style={{ fontSize: 25, fontFamily: "Rubik" }}>
                                            INSTRUTOR
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                    </Grid>

                </Container>
                <ModalNota
                    show={this.state.modalShow}
                    onHide={() => this.setState({ modalShow: false })}
                    listNotas={this.state.cursoNotas}>
                </ModalNota>
                <ModalHorarios
                    show={this.state.modalHorarioShow}
                    onHide={() => this.setState({ modalHorarioShow: false })} />
                <ModalInstrutor
                    show={this.state.modalInstrutorShow}
                    onHide={() => this.setState({ modalInstrutorShow: false })}
                    buscarCursoAtual={() => { this.buscarCursoAtual() }}
                    cursoAtual={this.state.curso}
                />
                <ModalAluno
                    show={this.state.modalAlunoShow}
                    onHide={() => this.setState({ modalAlunoShow: false })} />

                <ModalPremio
                    show={this.state.modalPremio}
                    onHide={() => this.setState({ modalPremio: false })}
                    msgPremio={this.state.curso.mensagemPremio} />
                <ModalParabens show={this.state.modalParabens}
                    onHide={() => this.setState({ modalParabens: false })}
                    nomeAluno={this.state.nomeAluno}
                />
                <ModalCursoGratis
                    show={this.state.modalCursoGratis}
                    onHide={() => this.setState({ modalCursoGratis: false })}
                    msgCGratis={this.state.msg_curso_gratis} />

                <NpsPesquisa curso={this.state.curso}/>     

                <AvaliacaoCurso curso={this.state.curso}/>  
              
                    {/* <section className="chatBotHelmet">
                        <HelmetProvider >
                            <Helmet>
                                <script type='text/javascript'>
                                    {this.state.scriptChatBotHelmet}
                                </script>
                            </Helmet>
                        </HelmetProvider>
                    </section> */}
            </>
        )
    }

}


const mapStateToProps = (state) => ({
    usuario: state.usuario
})

Home.contextType = AuthContext

export default connect(mapStateToProps)(withRouter(Home));