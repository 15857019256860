import React from "react";
import { Modal, Button, Accordion } from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


class ModalNota extends React.Component {

   
   
    calcularEstrelas = (nota) => {
        return nota / 20
    }  
    

    render() {
        return (
            <Modal                
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Notas
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.listNotas.length === 0 ? "Nenhuma nota foi encontrada!" :                  
                    
                    <Accordion>
                        {this.props.listNotas.map((curso) => (
                            <Accordion.Item eventKey={curso.idcurso} key={curso.idcurso} >
                                <Accordion.Header>{curso.curso}</Accordion.Header>
                                <Accordion.Body>
                                    <Grid>
                                        <TableContainer md={{ maxHeight: 400 }} sx={{ maxHeight: 300 }} sm={{ maxHeight: 400 }} xs={{ maxHeight: 500 }}>
                                            <Table aria-label="simple table" sx={{ minWidth: 50 }} >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Aula</TableCell>
                                                        <TableCell align="center">Data</TableCell>
                                                        <TableCell align="center">Nota</TableCell>
                                                        <TableCell align="right">Avaliação do Aluno</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {curso.notas.map((row) => (
                                                        <TableRow
                                                            key={row.n_prova}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.aula}
                                                            </TableCell>
                                                            <TableCell align="right">{new Date(row.data).toLocaleDateString()}</TableCell>
                                                            <TableCell align="center">


                                                                <Typography component="legend">{row.nota}</Typography>
                                                                <Rating name="half-rating-read" defaultValue={this.calcularEstrelas(row.nota)} precision={0.5} readOnly />


                                                            </TableCell>
                                                            <TableCell align="right">{row.avaliacaoAluno}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default ModalNota
