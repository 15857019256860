import React from "react";
import { Player, ControlBar, LoadingSpinner } from 'video-react';
import { withRouter } from 'react-router-dom';
import DigitacaoService from '../app/service/digitacaoService'
//import MatriculaService from "../app/service/matriculaService";

class TutorialDigitacao extends React.Component {

    constructor() {
        super()
        this.service = new DigitacaoService();      
        this.gravou = false;
       
    }

    componentDidMount() {

        this.buscarDigitacao();
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
        
    }

    handleStateChange(state) {

        this.setState({
            player: state
        });

        if (state.ended === true) {
            if (this.gravou === false) {               
                this.gravarTutorial();
                this.gravou = true;
            }
        }
    }

    gravarTutorial = () => {

        this.service.gravarTutorial(this.state.dadosDigitacao.tutorial)
            .then(response => {
                this.props.history.push('/digitacao');
            }).catch(erro => {
                console.error(erro.response.data)
            })

    }

    buscarDigitacao = async() => {
        await this.service.buscarAulaAtual()
            .then(response => {
              
                this.setState({ dadosDigitacao: response.data })

                if (response.data.tutorial === 0) {

                     
                     this.urlTutorial = "digitacao/videos/tutorial1.mp4";

                } else if (response.data.tutorial !== 2) {

                     
                     this.urlTutorial = "digitacao/videos/tutorial2.mp4";

                } else if (response.data.tutorial !== 3) {

                    
                     this.urlTutorial = "digitacao/videos/tutoacento.mp4";
                }

            }).catch(erro => {
                console.error(erro.response.data)
            })

    }

    state = {
      
        dadosDigitacao: null
    }


    render() {
        return (
            <Player autoPlay src={this.urlTutorial} ref={player => {
                this.player = player;
            }}
            >
                <LoadingSpinner />

                <ControlBar disableCompletely >

                </ControlBar>
            </Player>
        )
    }

}

export default withRouter(TutorialDigitacao)