import ApiService from "../apiservice";


class NotasExerciciosExtrasService extends ApiService{

    constructor(){
        super("/Notas/ExerciciosExtras")
    }

    buscarNotas(dto){
        return this.get(`?aula=${dto.aula}&curso=${dto.id}`)
    }

    gravarNota(dto){
        return this.post(`?nota=${dto.nota}&aula=${dto.aula}&curso=${dto.curso}`)
    }
}


export default NotasExerciciosExtrasService