import React from "react";
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import Home from "../views/home";
import Login from "../views/login";
import Player from "../views/player";
import Frequencia from "../views/frequencia";
import { AuthConsumer } from '../main/provedorAutenticacao'
import NavBar from '../components/navbar';
import Digitacao from '../views/digitacao'
import TutorialDigitacao from '../views/tutorialDigitacao'
import ReverAula from '../views/reverAula'
import playerReverAula from "../views/playerReverAula";
import playerExerciciosDigitais from "../views/playerExerciciosDigitais";
import perfilAluno from "../views/perfilAluno";
import playerApostila from "../views/playerApostila";


function RotaAutenticada({ component: Component, isUsuarioAutenticado, ocultarNavBar, ...props }) {
    return (
        <Route exact {...props} render={(componentProps) => {
            if (isUsuarioAutenticado) {
                if (ocultarNavBar) {
                    return (
                        <Component {...componentProps} />
                    )
                } else {
                    return (
                        <>
                            <NavBar />
                            <Component {...componentProps} />
                        </>
                    )
                }
            } else {
                return (
                    <Redirect to={{ pathname: '/login', state: { from: componentProps.location } }} />
                )
            }
        }} />
    )
}

function Rotas(props) {
    return (
        <HashRouter>
            <Switch>
                <Redirect exact path="/" to="/login" />
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/:url" component={Login} />
                <Route path="/playerApostila" component={playerApostila} /> 
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={false} path="/home" component={Home} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={true} path="/player" component={Player} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={true} path="/digitacao" component={Digitacao} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={true} path="/tutorialDig" component={TutorialDigitacao} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={false} path="/reverAula" component={ReverAula} />    
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={true} path="/playerReverAula/:codigoCurso/:idcurso/aula/:aula" component={playerReverAula} />    
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={true} path="/playerExerciciosDigitais/:curso/mod/:mod/aula/:aula" component={playerExerciciosDigitais} />                           
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={false} path="/frequencia" component={Frequencia} />
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} ocultarNavBar={false} path="/perfilAluno" component={perfilAluno} /> 
                
            </Switch>
        </HashRouter>
    )
}


const rot = () => {
    return (
        <AuthConsumer>
            {(context) => (<Rotas isUsuarioAutenticado={context.isAutenticado} />)}
        </AuthConsumer>
    )
}

export default rot