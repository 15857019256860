import ApiService from "../apiservice";


class DadosAulaService extends ApiService{

    constructor(){
        super("/checkpoint")
    }

    buscarDados(idcurso){
        return this.get(`?idcurso=${idcurso}`)
    }


    gravarDados(dto){
        return this.post("", dto)
    }

    deletarDados(idcurso){
        return this.delete(`?idcurso=${idcurso}`)
    }

}


export default DadosAulaService