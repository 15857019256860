
import { Modal } from "react-bootstrap";
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HorariosService from "../../app/service/horariosService";

class ModalHorarios extends React.Component {


    constructor() {
        super()
        this.service = new HorariosService();

    }

    componentDidMount() {

        
        this.service.buscarHorariosDeAula()
            .then(response => {            
                if (response.data === "Liberar sem horário") {
                    this.setState({ liberarSemHorario: true })
                } else {
                    this.setState({ horarios: response.data })
                }
            }).catch(error => {
                console.error(error.response.data)
            })

    }

    state = {
        horarios: [],
        liberarSemHorario: false
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    Horários de Aula
                </Modal.Header>
                <Modal.Body>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 100 }} aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {this.state.liberarSemHorario ?
                                        <TableCell align="center">Horários</TableCell>
                                        :
                                        <>
                                            <TableCell>Dia da Semana</TableCell>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell align="right">Início</TableCell>
                                            <TableCell align="right">Fim</TableCell>
                                        </>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.liberarSemHorario ?
                                    <TableRow>
                                        <TableCell align="center">Todos os horários liberados</TableCell>
                                    </TableRow>

                                    :
                                    this.state.horarios.map((row) => (
                                        <TableRow
                                            key={row.codigo}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.diasemana}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.tipoAula}
                                            </TableCell>
                                            <TableCell align="right">{row.inicio}</TableCell>
                                            <TableCell align="right">{row.fim}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Modal.Body>
            </Modal>
        )
    }

}

export default ModalHorarios