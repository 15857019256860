import React from "react";
import { Modal, Row } from "react-bootstrap";

export default function ModalAlbumFigurinhas(props) {
  const caminhoFigurinha = () => {
    switch (props.aulaAtual) {
      case 1:
        return 1;
      case 32:
        return 31;
      default:
        return props.aulaAtual % 2 === 0
          ? props.aulaAtual - 2
          : props.aulaAtual - 1;
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Row>
          <img
            src={`figurinhasGamesTeen/aula${caminhoFigurinha()}.png`}
            alt="albumFigurinhas"
            loading="eager"
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
}
