import ApiService from "../apiservice";


class AlunoService extends ApiService{

    constructor(){
        super("/Alunos")
    }

    verificarAniversario(){
        return this.get("/VerificarAniversario")
    }

}


export default AlunoService