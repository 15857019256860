import React from "react";
import { Container } from "react-bootstrap";
import FrequenciaService from "../app/service/frequenciaService";
import LocalStorageService from "../app/service/localstorageService";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Card } from '@mui/material';
import { Button } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Chip from '@mui/material/Chip';

class Frequencia extends React.Component {


    constructor() {
        super();
        this.service = new FrequenciaService();
        this.storage = new LocalStorageService();
    }

    componentDidMount() {

        this.service.buscarFrequencia()
            .then(response => {
                this.setState({ frequencias: response.data })
            }).catch(error => {
                console.error(error.response.data)
            })

    }

    state = {
        frequencias: []        
    }




    render() {
        return (

            <Container className="mt-5">
                       
                <Card>
                    <div style={{marginTop:5, marginLeft: 5}}>
                        <Button href="#/home" variant="secondary"><ArrowBackIcon /> Voltar</Button>

                    </div>
                    <TableContainer md={{ maxHeight: 400 }} sx={{ maxHeight: 1000 }} sm={{ maxHeight: 400 }} xs={{ maxHeight: 500 }}>
                        <Table aria-label="simple table" sx={{ minWidth: 50 }} >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Início</TableCell>
                                    <TableCell>Fim</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.frequencias.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            {new Date(row.data).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            {row.presente === "S"? 
                                             <Chip label="Presente" color="primary" />
                                             : 
                                             <Chip label="Ausente" color="secondary" />
                                             }
                                           
                                        </TableCell>
                                        <TableCell>
                                            {row.inicio}
                                        </TableCell>
                                        <TableCell>
                                            {row.fim}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>

        )
    }

}

export default Frequencia