import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL

const httpClient = axios.create({
    baseURL : baseURL
})


class ApiService {


    constructor(apiurl){
        this.apiurl = apiurl;
    }    

    static registrarToken(token){
        if(token){
            httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
        }        
    }

    post(url, objeto){
        return httpClient.post(this.apiurl + url, objeto);
    }

    put(url, objeto){
        return httpClient.put(url, objeto);
    }

    delete(url){
        return httpClient.delete(this.apiurl + url);
    }

    get(url){
        return httpClient.get(this.apiurl + url);
    }

}

export default ApiService;