import ApiService from "../apiservice";


class PerfilAlunoService extends ApiService{

    constructor(){
        super("/PerfilPortalAluno")
    }

    gravarPerfil(dto){
        return this.post("", dto)
    }

    buscarPerfil(){
        return this.get("")
    }
}

export default PerfilAlunoService