import React from "react";
import { withRouter } from 'react-router-dom'
import { Container, Card, Accordion, Button } from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CursoService from "../app/service/cursoService";
import Grid from '@mui/material/Grid';
import AirplayIcon from '@mui/icons-material/Airplay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as message from "../components/toastr";

class ReverAula extends React.Component {


    constructor() {
        super()
        this.service = new CursoService();
    }

    state = {
        refazer: [],
        carregando: true
    }

    componentDidMount() {      

        this.service.buscarRefazerAulas()
            .then(response => {
                if(response.data === "Você não possui aulas para refazer!"){                   
                    message.mensagemAlert(response.data);
                    this.setState({ carregando: false })
                }else{
                    
                    this.setState({ refazer: response.data, carregando: false })
                }

            }).catch(erro => {
                console.error(erro.response.data)
            })
    }

    player = (codigoCurso, aula, idcurso) => {
        this.props.history.push(`/playerReverAula/${codigoCurso}/${idcurso}/aula/${aula}`)
    }

    render() {
        return (

            <Container fluid="md mt-5" >
                <Card>
                    <div style={{ marginTop: 5, marginLeft: 5 }}>
                        <Button href="#/home" variant="secondary"><ArrowBackIcon /> Voltar</Button>

                    </div>
                    
                    <Card.Body>
                        {this.state.carregando ?
                            <>
                                Carregando...
                            </>
                            :

                            <Accordion>
                                {this.state.refazer.length === 0 ? "Nenhum dado foi encontrado" : ""}
                                {this.state.refazer.map((curso, index) => (

                                    <Accordion.Item eventKey={curso.codigoCurso} key={index} >

                                        <Accordion.Header>{curso.descricaoCurso}</Accordion.Header>
                                        <Accordion.Body>
                                            <Grid>
                                                <TableContainer md={{ maxHeight: 400 }} sx={{ maxHeight: 500 }} sm={{ maxHeight: 700 }} xs={{ maxHeight: 500 }}>
                                                    <Table aria-label="simple table" sx={{ minWidth: 50 }} >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">Aula</TableCell>
                                                                <TableCell align="center">Descrição</TableCell>
                                                                <TableCell align="right"></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {curso.aulas.map((aula, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {aula.numeroAula}
                                                                    </TableCell>
                                                                    <TableCell align="center">{aula.descricaoAula}</TableCell>
                                                                    <TableCell align="center">
                                                                        <Button onClick={(e) => {this.player(curso.codigoCurso, aula.numeroAula, curso.idCurso)}} title="Rever"><AirplayIcon /></Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Accordion.Body>

                                    </Accordion.Item>

                                ))}
                            </Accordion>
                        }

                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default withRouter(ReverAula)