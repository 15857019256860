
import ApiService from "../apiservice";



class HorariosService extends ApiService{

    constructor(){
        super('/matricula')
    }

    buscarHorariosDeAula(){
        return this.get("/horarios")
    }

}

export default HorariosService