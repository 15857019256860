import ApiService from "../apiservice";


class HomeService extends ApiService{

    constructor(){
        super('/curso')
    }

    buscarCursoAtual(){
        return this.get("/curso-atual")
    }

    buscarExerciciosDigitais(){
        return this.get("/ExerciciosDigitais")
    }

}

export default HomeService;