import ApiService from "../apiservice";

class FuncionarioService extends ApiService{

   
    constructor(){
        super('/funcionarios')
    }

    autenticar(func){
        return this.post("/autenticar", func)
    }

}

export default FuncionarioService