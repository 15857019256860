export default class LocalStorageService {
    
    static adicionarItem(chave, valor){
        sessionStorage.setItem(chave, JSON.stringify(valor));
    }

    static obterItem(chave){
        const item = sessionStorage.getItem(chave);
        return JSON.parse(item)
    }

    static removerItem(chave){
        sessionStorage.removeItem(chave)
    }

    
}