
import ApiService from "../apiservice";



class FrequenciaService extends ApiService{

    constructor(){
        super('/matricula')
    }

    buscarFrequencia(){
        return this.get("/frequencia")
    }

}

export default FrequenciaService