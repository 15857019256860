import ApiService from "../apiservice";



class NotasService extends ApiService{

    constructor(){
        super('/Notas')
    }

    buscarNotas(){

        return this.get("/notas");
    }

    gravarNota(notaDto){        
        return this.post("/inserirNota", notaDto);
    }

    gravarNotaSemAvancarAula(notaDto){
        return this.post("/gravarNotaSemAvancarAula", notaDto);
    }

    verificaSePossuiNota(idcurso, aula){
        return this.get(`/verificaSePossuiNota?idcurso=${idcurso}&aula=${aula}`);
    }

}

export default NotasService