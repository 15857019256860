import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Button, Form, Container } from "react-bootstrap";
import { Card } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PerfilAlunoService from "../app/service/perfilAlunoService";
import * as message from "../components/toastr";
import Alert from 'react-bootstrap/Alert';

function PerfilALuno() {


    const [nPessoas, setNPessoas] = useState("");
    const [sitMoradia, setSitMoradia] = useState("");
    const [internet, setInternet] = useState("");
    const [tempoLivre, setTempoLivre] = useState("");
    const [tipoFilme, setTipoFilme] = useState("");
    const [dicaFilme, setDicaFilme] = useState("");
    const [tipoLivro, setTipoLivro] = useState("");
    const [dicaLivro, setDicaLivro] = useState("");
    const [viagem, setViagem] = useState("");
    const [cor, setCor] = useState("");
    const [signo, setSigno] = useState("");
    const [motivoCurso, setMotivoCurso] = useState("");
    const [obterCurso, setObterCurso] = useState("");
    const [aposCurso, setAposCurso] = useState("");
    const [atFisicas, setAtFisicas] = useState("");
    const [esporte, setEsporte] = useState("");
    const [timeFut, setTimeFut] = useState("");
    const [qualcurso, setQualcurso] = useState("");
    const [salvo, setSalvo] = useState(false);

    const [idade, setIdade] = useState(null);
    const [escolaridade, setEscolaridade] = useState("");
    const [ocupacao, setOcupacao] = useState("");
    const [objetivos, setObjetivos] = useState("");
    const [assuntos_interessa, setAssuntos_interessa] = useState("");
    const [fontes_informacoes, setFontes_informacoes] = useState("");
    const [rede_social_favorita, setRede_social_favorita] = useState("");
    const [influencer_de_sucesso, setInfluencer_de_sucesso] = useState("");
    const [porque_nossa_empresa, setPorque_nossa_empresa] = useState("");
    const [como_ficou_sabendo_nossa_empresa, setComo_ficou_sabendo_nossa_empresa] = useState("");



    useEffect(() => {
        const service = new PerfilAlunoService()

        service.buscarPerfil().then(response => {
           
            if(response.data != null){
                const dadosPerfil = response.data;
                setNPessoas(dadosPerfil.numPessoas)
                setSitMoradia(dadosPerfil.sitMoradia)
                setInternet(dadosPerfil.internet)
                setTempoLivre(dadosPerfil.tempoLivre)
                setTipoFilme(dadosPerfil.filme)
                setDicaFilme(dadosPerfil.dicaFilme)
                setTipoLivro(dadosPerfil.livro)
                setDicaLivro(dadosPerfil.dicaLivro)
                setViagem(dadosPerfil.viagemSonhos)
                setCor(dadosPerfil.cor)
                setSigno(dadosPerfil.signo)
                setMotivoCurso(dadosPerfil.motivoCurso)
                setObterCurso(dadosPerfil.obterCurso)
                setAposCurso(dadosPerfil.aposCurso)
                setAtFisicas(dadosPerfil.atFisicas)
                setEsporte(dadosPerfil.esporte)
                setTimeFut(dadosPerfil.timeFut)
                setQualcurso(dadosPerfil.qualCurso)
                setSalvo(dadosPerfil.nPessoas)

                setIdade(dadosPerfil.idade)
                setEscolaridade(dadosPerfil.escolaridade)
                setOcupacao(dadosPerfil.ocupacao)
                setObjetivos(dadosPerfil.objetivos)
                setAssuntos_interessa(dadosPerfil.assuntos_interessa)
                setFontes_informacoes(dadosPerfil.fontes_informacoes)
                setRede_social_favorita(dadosPerfil.rede_social_favorita)
                setInfluencer_de_sucesso(dadosPerfil.influencer_de_sucesso)
                setPorque_nossa_empresa(dadosPerfil.porque_nossa_empresa)
                setComo_ficou_sabendo_nossa_empresa(dadosPerfil.como_ficou_sabendo_nossa_empresa)
            }
        }).catch(erro => {
            console.error(erro)
        })
            

    }, []);


    const enviar = (event) => {

        const parametro = { n_pessoas: nPessoas, sitMoradia, internet, tempoLivre, tipoFilme, dicaFilme, tipoLivro, dicaLivro, viagem, cor, signo, motivoCurso, obterCurso, aposCurso, atFisicas, timeFut, qualcurso, idade, esporte, ocupacao, objetivos, assuntos_interessa, fontes_informacoes, rede_social_favorita, influencer_de_sucesso, porque_nossa_empresa, escolaridade, como_ficou_sabendo_nossa_empresa }

        const service = new PerfilAlunoService()

        service.gravarPerfil(parametro)
            .then(response => {
                message.mensagemSucesso("Salvo com Sucesso!")
                setSalvo(true)
            }).catch(erro => {
                message.mensagemErro("Erro ao salvar!")
                console.error("Erro gravar perfil ", erro)
            })
        event.preventDefault();
    }

    return (
        <Container className="mt-5">
            <Card>
                <div style={{ marginTop: 5, marginLeft: 5 }}>
                    <Button href="#/home" variant="secondary"><ArrowBackIcon /> Voltar</Button>
                </div>
                <Container style={{ marginTop: 5, marginLeft: 5 }}>
                    <Form className="mt-3" onSubmit={enviar}>
                        <Form.Group className="mb-3" controlId="formBasicNPessoas">
                            <Form.Label>Quantas pessoas moram com você?</Form.Label>
                            <Form.Select id="qpessoas" value={nPessoas} onChange={e => { setNPessoas(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option value="0">Nenhuma</option>
                                <option value="1">Uma</option>
                                <option value="2">Duas</option>
                                <option value="3">Três</option>
                                <option value="4">Quatro ou mais</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicMoradia">
                            <Form.Label>Qual a situação de sua moradia?</Form.Label>
                            <Form.Select id="moradia" value={sitMoradia} onChange={e => { setSitMoradia(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Mora em casa própria</option>
                                <option>Moro em casa alugada</option>
                                <option>Moro em casa financiada</option>
                                <option>Moro com familiares</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicInternet">
                            <Form.Label>Onde você mais acessa a internet?</Form.Label>
                            <Form.Select id="internet" value={internet} onChange={e => { setInternet(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Em casa</option>
                                <option>Na escola</option>
                                <option>No trabalho</option>
                                <option>Pelo celular</option>
                                <option>Não tenho acesso</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicTempoLivre">
                            <Form.Label>Como você utiliza seu tempo livre?</Form.Label>
                            <Form.Select id="internet" value={tempoLivre} onChange={e => { setTempoLivre(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Internet</option>
                                <option>Jogos</option>
                                <option>Televisão</option>
                                <option>Esportes</option>
                                <option>Outros</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicTipoFilme">
                            <Form.Label>Qual seu tipo de filme favorito?</Form.Label>
                            <Form.Select id="tipoFilme" value={tipoFilme} onChange={e => { setTipoFilme(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Terror</option>
                                <option>Comédia</option>
                                <option>Guerra</option>
                                <option>Ação</option>
                                <option>Romance</option>
                                <option>Aventura</option>
                                <option>Suspense</option>
                                <option>Ficção Científica</option>
                                <option>Musical</option>
                                <option>Faroeste</option>
                                <option>Drama</option>
                                <option>Animação</option>
                                <option>Policial</option>
                                <option>Fantasia</option>
                                <option>Outra</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicDicaFilme">
                            <Form.Label>Poderia nos dar algumas dicas de filmes?</Form.Label>
                            <Form.Control minLength={3} maxLength={400} value={dicaFilme} onChange={e => { setDicaFilme(e.target.value) }} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicTipoLivro">
                            <Form.Label>Qual seu tipo de livro favorito?</Form.Label>
                            <Form.Select id="tipoLivro" value={tipoLivro} onChange={e => { setTipoLivro(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Terror</option>
                                <option>Comédia</option>
                                <option>Guerra</option>
                                <option>Ação</option>
                                <option>Romance</option>
                                <option>Aventura</option>
                                <option>Suspense</option>
                                <option>Ficção Científica</option>
                                <option>Policial</option>
                                <option>Fantasia</option>
                                <option>Poesia</option>
                                <option>Outra</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicDicaLivro">
                            <Form.Label>Poderia nos dar algumas dicas de livros?</Form.Label>
                            <Form.Control minLength={3} maxLength={400} value={dicaLivro} onChange={e => { setDicaLivro(e.target.value) }} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicViagem">
                            <Form.Label>Descreva em poucas palavras qual a viagem de seus sonhos:</Form.Label>
                            <Form.Control minLength={5} maxLength={500} value={viagem} onChange={e => { setViagem(e.target.value) }} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicCor">
                            <Form.Label>Qual sua cor preferida?</Form.Label>
                            <Form.Select id="tipoLivro" value={cor} onChange={e => { setCor(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Azul</option>
                                <option>Amarelo</option>
                                <option>Verde</option>
                                <option>Vermelho</option>
                                <option>Branco</option>
                                <option>Preto</option>
                                <option>Rosa</option>
                                <option>Lilás</option>
                                <option>Pink</option>
                                <option>Outra</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicSigno">
                            <Form.Label>Qual seu signo?</Form.Label>
                            <Form.Select id="tipoLivro" value={signo} onChange={e => { setSigno(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Áries</option>
                                <option>Touro</option>
                                <option>Gêmeos</option>
                                <option>Câncer</option>
                                <option>Leão</option>
                                <option>Virgem</option>
                                <option>Libra</option>
                                <option>Escorpião</option>
                                <option>Sagitário</option>
                                <option>Capricórnio</option>
                                <option>Aquário</option>
                                <option>Peixes</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicMotivoCurso">
                            <Form.Label>Qual o principal motivo para você ter escolhido este curso?</Form.Label>
                            <Form.Select id="motivoCurso" value={motivoCurso} onChange={e => { setMotivoCurso(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Indicação de amigos</option>
                                <option>Eu já queria</option>
                                <option>Aproveitei uma promoção</option>
                                <option>Outro</option>
                                <option>Nenhum motivo</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicObtCurso">
                            <Form.Label>O que você espera obter após realizar este curso?</Form.Label>
                            <Form.Select id="obterCurso" value={obterCurso} onChange={e => { setObterCurso(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Aumentar meus conhecimentos</option>
                                <option>Conseguir um emprego</option>
                                <option>Melhorar minha posição na empresa em que trabalho</option>
                                <option>Sentir-me realizado</option>
                                <option>Outro</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicAposCurso">
                            <Form.Label>Após, finalizar este curso, deseja realizar outros?</Form.Label>
                            <Form.Select id="aposCurso" value={aposCurso} onChange={e => { setAposCurso(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Sim</option>
                                <option>Não</option>
                            </Form.Select>
                        </Form.Group>

                        {aposCurso === "Sim" ?

                            <Form.Group className="mb-3" controlId="formBasicQualCurso">
                                <Form.Label>Qual curso gostaria de realizar?</Form.Label>
                                <Form.Control value={qualcurso} onChange={e => { setQualcurso(e.target.value) }} />
                            </Form.Group>
                            :
                            <></>
                        }


                        <Form.Group className="mb-3" controlId="formBasicAtFisicas">
                            <Form.Label>Você pratica atividades físicas?</Form.Label>
                            <Form.Select id="atFisicas" value={atFisicas} onChange={e => { setAtFisicas(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Nunca</option>
                                <option>Raramente</option>
                                <option>Ocasionalmente</option>
                                <option>Sempre</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEsporte">
                            <Form.Label>Qual seu esporte favorito?</Form.Label>
                            <Form.Select id="esporte" value={esporte} onChange={e => { setEsporte(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Não gosto de esporte</option>
                                <option>Futebol</option>
                                <option>Vôlei</option>
                                <option>Tênis de Mesa</option>
                                <option>Natação</option>
                                <option>Futsal</option>
                                <option>Capoeira</option>
                                <option>Skate</option>
                                <option>Surfe</option>
                                <option>Judô</option>
                                <option>Atletismo</option>
                                <option>Basquete</option>
                                <option>Handebol</option>
                                <option>Tênis</option>
                                <option>Outro</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicTimeFut">
                            <Form.Label>Você torce para qual time?</Form.Label>
                            <Form.Select id="timeFut" value={timeFut} onChange={e => { setTimeFut(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Não tenho time</option>
                                <option>América Mineiro</option>
                                <option>Atlético Goianiense</option>
                                <option>Atlético Mineiro</option>                                
                                <option>Atlético Paranaense</option>
                                <option>Avaí</option>
                                <option>Bahia</option>
                                <option>Botafogo</option>
                                <option>Ceará</option>
                                <option>Chapecoense</option>
                                <option>Corinthians</option>
                                <option>Coritiba</option>
                                <option>Criciúma</option>
                                <option>Cruzeiro</option>
                                <option>Figueirense</option>
                                <option>Flamengo</option>
                                <option>Fluminense</option>
                                <option>Fortaleza</option>
                                <option>Goiás</option>
                                <option>Grêmio</option>
                                <option>Internacional</option>
                                <option>Joinville</option>
                                <option>Juventude</option>
                                <option>Náutico</option>
                                <option>Palmeiras</option>
                                <option>Paraná</option>
                                <option>Paysandu</option>
                                <option>Ponte Preta</option>
                                <option>Santa Cruz</option>
                                <option>Santos</option>
                                <option>São Paulo</option>
                                <option>Sport</option>
                                <option>Vasco</option>
                                <option>Vitória </option>
                                <option>Outro</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicIdade">
                                <Form.Label>Qual a sua idade?</Form.Label>
                                <Form.Control value={idade} maxLength={2} type="number" onChange={e => { setIdade(e.target.value) }} required/>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicEscolaridade">
                            <Form.Label>Qual o seu nível de escolaridade?</Form.Label>
                            <Form.Select id="escolaridade" value={escolaridade} onChange={e => { setEscolaridade(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Fundamental - Cursando</option>
                                <option>Fundamental - Incompleto</option>
                                <option>Fundamental - Completo</option>                               
                                <option>Médio - Cursando</option>
                                <option>Médio - Incompleto</option>
                                <option>Médio - Completo</option>
                                <option>Superior - Cursando</option>
                                <option>Superior - Incompleto</option>
                                <option>Superior - Completo</option>
                                <option>Pós-graduação - Cursando</option>
                                <option>Pós-graduação - Incompleto</option>
                                <option>Pós-graduação - Completo</option>
                                <option>Pós-graduação (nível mestrado) - Cursando</option>
                                <option>Pós-graduação (nível mestrado) - Incompleto</option>
                                <option>Pós-graduação (nível mestrado) - Completo</option>
                                <option>Pós-graduação (nível doutor) - Cursando</option>
                                <option>Pós-graduação (nível doutor) - Incompleto</option>
                                <option>Pós-graduação (nível doutor) - Completo</option>                                
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicOcupacao">
                            <Form.Label>Qual a sua ocupação?</Form.Label>
                            <Form.Select id="ocupacao" value={ocupacao} onChange={e => { setOcupacao(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Estuda</option>
                                <option>Trabalha</option>
                                <option>Buscando emprego</option>       
                                <option>Desempregado</option>   
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicObjetivo">
                                <Form.Label>Quais são os seus principais objetivos, desejos, ambições, medos e desafios?</Form.Label>
                                <Form.Control value={objetivos} onChange={e => { setObjetivos(e.target.value) }} required/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicAssuntos_interessa">
                                <Form.Label>Por quais assuntos você mais se interessa?</Form.Label>
                                <Form.Control value={assuntos_interessa} onChange={e => { setAssuntos_interessa(e.target.value) }} required/>
                        </Form.Group>
                 
                        <Form.Group className="mb-3" controlId="formBasicFontes_informacoes">
                            <Form.Label>Qual a sua principal fonte de informação?</Form.Label>
                            <Form.Select id="fonteInformacao" value={fontes_informacoes} onChange={e => { setFontes_informacoes(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Redes Sociais</option>
                                <option>Sites de internet</option>
                                <option>Rádio</option>       
                                <option>Jornais impressos</option>   
                                <option>Revistas</option>     
                                <option>Outro</option>   
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicRedeSocial">
                            <Form.Label>Quais a sua rede social favorita?</Form.Label>
                            <Form.Select id="rede_social_favorita" value={rede_social_favorita} onChange={e => { setRede_social_favorita(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Facebook</option>
                                <option>Instagram</option>
                                <option>LinkedIn</option>       
                                <option>TikTok</option>   
                                <option>Twitter</option>     
                                <option>Outra</option>   
                            </Form.Select>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formBasicInfluencer_de_sucesso">
                                <Form.Label>Quem você considera um influenciador digital de sucesso, que você siga nas redes sociais, cite 3?(Tiago Nigro, Bruno Perini, Lucas Neto)</Form.Label>
                                <Form.Control value={influencer_de_sucesso} onChange={e => { setInfluencer_de_sucesso(e.target.value) }} required/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPorque_nossa_empresa">
                                <Form.Label>O que te fez escolher a nossa empresa?</Form.Label>
                                <Form.Control value={porque_nossa_empresa} onChange={e => { setPorque_nossa_empresa(e.target.value) }} required/>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicComo_ficou_sabendo_nossa_empresa">
                            <Form.Label>Como você ficou sabendo da nossa empresa?</Form.Label>
                            <Form.Select id="como_ficou_sabendo_nossa_empresa" value={como_ficou_sabendo_nossa_empresa} onChange={e => { setComo_ficou_sabendo_nossa_empresa(e.target.value) }} required>
                                <option value="">(Selecione uma opção)</option>
                                <option>Rádio</option>
                                <option>Jornais</option>
                                <option>Rede Social</option>       
                                <option>Google</option>   
                                <option>Amigos</option>     
                                <option>Família</option>   
                                <option>Escola</option>  
                                <option>Outro</option>  
                            </Form.Select>
                        </Form.Group>

                        {salvo ?
                            <Alert key={1} variant={"success"}>
                                Seu perfil foi salvo!{" "}
                                <Alert.Link href="#/home">Voltar</Alert.Link>
                            </Alert>
                            :
                            <></>
                        }
                        <Button className="mb-2" variant="primary" type="submit">
                            Salvar!
                        </Button>
                    </Form>
                </Container>
            </Card>
        </Container>
    )
}

export default withRouter(PerfilALuno);