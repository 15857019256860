import React from "react";
import { withRouter } from 'react-router-dom'
import DigitacaoService from '../app/service/digitacaoService'
import * as message from "../components/toastr";
import '../../node_modules/video-react/dist/video-react.css';
import FrameDigitacao from "../components/frameDigitacao";


class Digitacao extends React.Component {

    constructor() {
        super();       
        this.service = new DigitacaoService();  
    }

    state = {

        urlAula: "",
        digitacao : null

    }

    async componentDidMount() {
       
       await this.buscarAulaAtual();

    }

    
    buscarAulaAtual = async () => {
        await this.service.buscarAulaAtual()
        .then(response => {
           
           
            if(response.data.aula === 1 && response.data.tutorial < 1){
            
                this.props.history.push('/tutorialDig');
            
            }else if (response.data.aula === 17 && response.data.tutorial < 2){

                this.props.history.push('/tutorialDig');

            }else if (response.data.aula === 30 && response.data.tutorial < 3){

                this.props.history.push('/tutorialDig');

            }

           
             this.setState({urlAula : `./digitacao/Aula${response.data.aula}/index.html`})
             this.setState({digitacao : response.data})
             this.setState({maximoErro : response.data.maximoerro} , () => {})
             this.setState({maximodigitacao : response.data.maximodigitacao} , () => {})



        }).catch(erro => {
            console.error(erro.response)
        })
    }
  
    aprovadoDigitacao = () => {
        this.service.avancarAula()
        .then(response => {
            
        }).catch(erro => {
            console.error(erro.response.data)
            message.mensagemErro("Erro")
        })

    } 


    setarPontuacao = () => {
        //buscar a quantidade de erros e acertos na tabela parametros do cliente
        if(this.state.maximoErro > 0 && this.state.maximodigitacao > 0){
            
            window.frames['frameDig'].setarPontuacao(this.state.maximoErro, this.state.maximodigitacao);

        }else{

            window.frames['frameDig'].setarPontuacao(15, 150);
        }

    }

    render() {
        return (
                            
                <FrameDigitacao 
                buscarAulaAtual={() => {this.buscarAulaAtual()}}
                aprovadoDigitacao={() => {this.aprovadoDigitacao()}}
                history={this.props.history}
                urlAula={this.state.urlAula}
                setarPontuacao={() => {this.setarPontuacao()}}
               
                />          

        )
    }
}

export default withRouter(Digitacao);