import { useEffect } from "react";
import Iframe from 'react-iframe'

const FramePlayer = (props) => {


    useEffect(() => {
        const handler = e => {
            
            if (e.data === 'loja') {

                props.history.push('/home');

            } else if (e.data === 'verificaAShistoria') {

                //registra no storate que o tutoral já foi reproduzido
                props.verificaAShistoria();


            } else if (e.data === 'verificaAStutorial') {

                props.verificaAStutorial();


            } else if (e.data >= 0 && e.data <= 100 && props.gravouNota === false) {


                let nota = window.frames['frameAula'].cpAPIInterface.getVariableValue("cpInfoPercentage");
                console.log("Gravar nota " + nota)
                props.gravarNota(nota);
                return;

            } else if (e.data === "mapaCurso") {
                              
                props.mapaCurso();

            } else if (e.data === "mapaPrincipal") {

                props.mapaPrincipal();

            } else if (e.data === "verificaAula") {
               
                props.verificaAula();

            } else if (e.data === "chamaMapaCurso") {

                props.chamaMapaCurso();

            } else if (e.data === "chamaAula") {

                props.chamaAula();

            } else if (e.data === "chamaHome") {

                props.history.push('/home');

            } else if (e.data === "chamaHistoria") {

                props.chamaHistoria()

            } else if (e.data === "chamaTutorial") {


                props.chamaTutorial()

            } else if (e.data === "sairAS") {

                props.history.push('/home');

            } else if (e.data === "buscarOndeParou") {                
                props.buscarOndeParou()

            } else {

                props.respostaframe(e.data);

            }
        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // empty array => run only once

    return (
        <Iframe url={props.urlAula}
            width="100%"
            height="100%"
            id="frameAula"
            name="frameAula"
            allowFullScreen="true"
            className="myClassname"
            display="initial"
            position="absolute"
        />
    )
}

export default FramePlayer