import { useEffect } from "react";
import Iframe from 'react-iframe'

const FrameDigitacao = (props) => {


    useEffect(() => {
        const handler = e => {

            
            if (e.data === 'sairDigitacao') {
               
                props.history.push('/home');
    
            } else if (e.data === 'avancarAulaDigitacao') {
           
                props.buscarAulaAtual();
    
            } else if (e.data === 'aprovadoDigitacao') {
                
                props.aprovadoDigitacao();
    
            } else if (e.data === 'buscarPontuacao') {
                    
                props.setarPontuacao()
    
            }
        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // empty array => run only once

    return (
        <Iframe url={props.urlAula}
                    width="100%"
                    height="100%"
                    id="frameDig"
                    name="frameDig"
                    allowFullScreen="true"
                    className="myClassname"
                    display="initial"
                    position="absolute"
                />     
    )
}

export default FrameDigitacao