import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import * as message from "../components/toastr";
import NpsService from "../app/service/npsService"

export function Notas(props) {


    const rows = [];
    for (var i = 0; i < 11; i++) {
        let idbt = `btnNps${i}`;
        rows.push(
            <div key={i} className="ms-2">
                <input type="radio" className="btn-check" name="btnNota" id={idbt} onClick={() => { props.notaSelecionada() }} autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor={idbt}>{i}</label>
            </div>
        )

    }
    return (
        rows
    )

}

export default function NpsPesquisa(props) {

    const [exibirModal, setExibirModal] = useState(false);
    const [motivo, setMotivo] = useState("");
    const [responderNPS, setresponderNPS] = useState(false);
    const [notaSelecionada, setnotaSelecionada] = useState(false);

    useEffect(() => {

        var aulaAtual = props.curso.aulaAtual;       

        if (aulaAtual >= 10 && !responderNPS) {

            const npsService = new NpsService();
            npsService.reponderNPS(props.curso.id).then(response => {
                if (response.data) {
                    setresponderNPS(true)
                    setExibirModal(true)
                }
            }).catch(erro => {
                console.error(erro)
                setExibirModal(false)
                setresponderNPS(false)
            })
        } else if (aulaAtual >= 10 && responderNPS) {
            setExibirModal(true)
        }
    }, [props.curso.aulaAtual, props.curso.id, responderNPS]);

    const enviar = () => {

        var nota = pegarNotaSelecionada();

        if (nota === null) {
            message.mensagemAlert("Nota obrigatória")
        } else {

            var npsDto = { nota: nota, motivo: motivo }
            const npsService = new NpsService();
            npsService.gravarNPS(npsDto,props.curso.id).then(response => {
                setExibirModal(false)
            }).catch(erro => {
                console.error("Erro ao gravar NPS ", erro)
                setExibirModal(false)
            })
            setresponderNPS(false)
        }

    }

    const pegarNotaSelecionada = () => {

        for (var i = 0; i < 11; i++) {
            let idbt = `btnNps${i}`;
            if (document.getElementById(idbt).checked) {
                return i;
            }
        }

        return null;
    }

    return (<>
        <Modal show={exibirModal}
            onHide={() => { responderNPS ? setExibirModal(true) : setExibirModal(false) }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static">

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="notaota">
                        <Form.Label><b>Em uma escala de 0 a 10, o quanto você recomendaria nossa escola para um amigo ou colega de trabalho?</b></Form.Label>
                        <div className="btn-group btn-group-lg mb-2 ml-3 d-flex justify-content-center" role="group" aria-label="Large button group">

                            <Notas notaSelecionada={() => { setnotaSelecionada(true) }} />

                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="motivoNota">
                        <Form.Label>O que te motivou nesta nota?</Form.Label>
                        <Form.Control as="textarea" rows={5} placeholder="R..." maxLength={500} value={motivo} onChange={e => { setMotivo(e.target.value) }} />
                    </Form.Group>
                    <Button disabled={!notaSelecionada} onClick={() => { enviar() }}>Enviar</Button>
                </Form>
            </Modal.Body>
        </Modal>
    </>)
}