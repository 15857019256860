import ApiService from "../apiservice";

class ParametroService extends ApiService{
    
    constructor(){
        super('/Parametros')
    }

    planoDeFundo(url){

        return this.get(`/PlanoDeFundo?url=${url}`);

    }

}

export default ParametroService