import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: 'usuario',
    initialState:{
        nome:"",
        curso: []
    },
    reducers: {
        adicionarUsuario(state, {payload}){
            return {...state, 
                nome: payload.nome,
                curso: payload.curso
            }
        }
    }
})


export const {adicionarUsuario} = slice.actions

export const selectUser = state => state.usuario

export default slice.reducer