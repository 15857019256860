import React from "react";
import { Modal } from "react-bootstrap";
import CelebrationIcon from '@mui/icons-material/Celebration';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';


function ModalCursoGratis(props) {

    return (
        <Modal show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <CelebrationIcon />
                <EmojiEventsIcon />
                <CelebrationIcon />
            </Modal.Header>
            <Modal.Body>
                <h2>
                    {props.msgCGratis}
                </h2>
            </Modal.Body>
        </Modal>
    )
}

export default ModalCursoGratis