import ApiService from "../apiservice";


class AvaliacaoAulaCursoService extends ApiService{

    constructor(){
        super("/AvaliacaoCursos/aula")
    }

    /**
     * Verifica se o curso já foi avaliado
     * @param {*} idcurso 
     * @param {*} aula 
     * @version 0.0.1
     * @author Cristian Santos
     * @returns 
     */
    cursoJaAvaliado(idcurso, aula){
        return this.get(`?idcurso=${idcurso}&aula=${aula}`)
    }

    /**
     * Grava nota de avaliacao da aula realizada
     * @param {*} avaliacaoCursoDTO 
     * @param {*} idcurso 
     * @param {*} aula 
     * @version 0.0.1
     * @author Cristian Santos
     * @returns 
     */
    gravarNota(avaliacaoCursoDTO, idcurso, aula){
        return this.post(`?idcurso=${idcurso}&aula=${aula}`,avaliacaoCursoDTO)
    }
}

export default AvaliacaoAulaCursoService