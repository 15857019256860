import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as message from "../components/toastr";
import AvaliacaoCursoService from "../app/service/avaliacaoCursoService";

export function Notas(props) {
  const rows = [];
  for (var i = 0; i < 11; i++) {
    let idbt = `btnCurso${i}`;
    rows.push(
      <div key={i} className="ms-2">
        <input
          type="radio"
          className="btn-check"
          name="btnNota"
          id={idbt}
          onClick={() => {
            props.notaSelecionada();
          }}
          autoComplete="off"
        />
        <label className="btn btn-outline-primary" htmlFor={idbt}>
          {i}
        </label>
      </div>
    );
  }
  return rows;
}

export default function AvaliacaoCurso(props) {
  const [exibirModal, setExibirModal] = useState(false);
  const [comentario, setComentario] = useState("");
  const [avaliarCurso, setAvaliarCurso] = useState(false);
  const [notaSelecionada, setnotaSelecionada] = useState(false);

  useEffect(() => {
    var aulaQueOAlunoPrecisaAvaliar = props.curso.n_aulas - 1;
    var aulaAtual = props.curso.aulaAtual;

    if (aulaAtual === aulaQueOAlunoPrecisaAvaliar && !avaliarCurso) {
      const service = new AvaliacaoCursoService();

      service
        .cursoJaAvaliado(props.curso.id)
        .then((response) => {
          if (!response.data) {
            setAvaliarCurso(true);
            setExibirModal(true);
          }
        })
        .catch((erro) => {
          console.error(erro);
          setExibirModal(false);
          setAvaliarCurso(false);
        });
    } else if (aulaAtual === aulaQueOAlunoPrecisaAvaliar && avaliarCurso) {
      setExibirModal(true);
    }
  }, [
    props.curso.aulaAtual,
    props.curso.n_aulas,
    props.curso.id,
    avaliarCurso,
  ]);

  const enviar = () => {
    var nota = pegarNotaSelecionada();

    if (nota === null) {
      message.mensagemAlert("Nota obrigatória");
    } else {
      if (nota <= 6 && comentario.length < 8) {
        message.mensagemAlert("O que te motivou nesta nota?");
      } else {
        var avaliacaoDTO = { nota: nota, comentario: comentario };
        const service = new AvaliacaoCursoService();
        service
          .gravarNota(avaliacaoDTO, props.curso.id)
          .then(() => {
            setExibirModal(false);
          })
          .catch((erro) => {
            console.error("Erro ao gravar NPS ", erro);
            setExibirModal(false);
          });
        setAvaliarCurso(false);
      }
    }
  };

  const pegarNotaSelecionada = () => {
    for (var i = 0; i < 11; i++) {
      let idbt = `btnCurso${i}`;
      if (document.getElementById(idbt).checked) {
        return i;
      }
    }

    return null;
  };

  return (
    <>
      <Modal
        show={exibirModal}
        onHide={() => {
          avaliarCurso ? setExibirModal(true) : setExibirModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="notaota">
              <Form.Label>
                <b>
                  Por favor, avalie este curso de 0 a 10. Sua opinião é muito
                  importante para nós. Obrigado!
                </b>
              </Form.Label>
              <div
                className="btn-group btn-group-lg mb-2 ml-3 d-flex justify-content-center"
                role="group"
                aria-label="Large button group"
              >
                <Notas
                  notaSelecionada={() => {
                    setnotaSelecionada(true);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="motivoNota">
              <Form.Label>O que te motivou nesta nota?</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="R..."
                maxLength={500}
                value={comentario}
                onChange={(e) => {
                  setComentario(e.target.value);
                }}
              />
            </Form.Group>
            <Button
              disabled={!notaSelecionada}
              onClick={() => {
                enviar();
              }}
            >
              Enviar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
