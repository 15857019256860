
import { Modal, Row } from "react-bootstrap";
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AnalyticsIcon from '@mui/icons-material/Analytics';


class ModalMeusCursos extends React.Component {


    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    Meus Cursos
                    <AnalyticsIcon color="action" />
                </Modal.Header>
                <Modal.Body>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 100 }} aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ordem</TableCell>
                                    <TableCell>Curso</TableCell>
                                    <TableCell>Aula Atual</TableCell>
                                    <TableCell>Total de Aulas</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Andamento</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.cursos?.length <= 0 ?
                                    <TableRow>
                                        <TableCell align="center">Nenhum curso encontrado!</TableCell>
                                    </TableRow>

                                    :
                                    this.props.cursos.map((row) => (
                                        <TableRow
                                            key={row.codigoCurso}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.ordem}
                                            </TableCell>
                                            <TableCell component="th" scope="row" >
                                                {row.descricao}
                                            </TableCell>
                                            <TableCell >{row.aulaAtual}</TableCell>
                                            <TableCell >{row.totalDeAulas}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>
                                                {row.andamento === 100 ?
                                                    <ProgressBar animated now={row.andamento} label={`${row.andamento}%`} variant="success" />
                                                    :
                                                    <ProgressBar animated now={row.andamento} label={`${row.andamento}%`} />
                                                }


                                            </TableCell>

                                        </TableRow>
                                    ))
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        {this.props.cursos.length > 0 ?
                            <>
                                Andamento da Matrícula {`${this.props.porcentagemMat}%`}
                            </>
                            :
                            <>
                            </>
                        }

                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default ModalMeusCursos